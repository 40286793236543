import React from 'react'

const PremiumPolicyPage: React.FC = (): JSX.Element => {
  return (
    <section className="bg-pallet1  w-full p-4 rounded-xl border-pallet2 border">
      <div className="p-8 w-full mx-auto  rounded shadow">
        <h2 className="text-xl font-bold mb-4">TORNE-SE PREMIUM</h2>
        <p className="mb-4">
          A promoção "TORNE-SE PREMIUM" oferece aos clientes do nosso cassino a oportunidade de se tornarem membros Premium
          mediante um depósito de 200R$. Como membro Premium, os participantes têm direito a um reembolso de 50% das perdas
          líquidas que incorrerem nas atividades de jogo dentro do cassino durante um período especificado.
        </p>

        <div>
          <h3 className="text-lg font-bold mb-2">Termos e Condições</h3>
          <p className="mb-4">
            A promoção "TORNE-SE PREMIUM" é válida para todos os clientes do cassino que desejam aderir mediante o pagamento
            da taxa de adesão de 200R$.
          </p>

          <p className="mb-4">
            O pagamento da taxa de adesão de 200R$ é não reembolsável e não está sujeito a reembolso, independentemente do
            resultado da participação do cliente no cassino.
          </p>

          <p className="mb-4">
            Como membro Premium, o participante tem direito a receber um reembolso de 50% das perdas líquidas após suas
            apostas serem liquidadas.
          </p>

          <p className="mb-4">
            O reembolso de 50% das perdas líquidas será concedido na forma de crédito de jogo e estará sujeito a requisitos
            de apostas antes de poder ser sacado.
          </p>

          <p className="mb-4">A promoção "torne-se premium" tem uma duração de 7 DIAS.</p>

          <p className="mb-4">
            Os participantes da promoção "TORNE-SE PREMIUM" concordam em cumprir todos os termos e condições do cassino, bem
            como os termos específicos desta promoção.
          </p>

          <p className="mb-4">
            A administração da Bcash.bet se reserva o direito de alterar, suspender ou encerrar a promoção a qualquer
            momento, a seu critério.
          </p>
        </div>
        <p className="mb-6">
          A equipe de gestão da Bcash.bet tem autoridade para atualizar estas diretrizes a qualquer momento.
        </p>
      </div>
    </section>
  )
}

export default PremiumPolicyPage
