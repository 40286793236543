import React, { useEffect, useState } from 'react'
import { ButtonType } from '@/interfaces/application/buttonType'
import IconSpinner from '../Icons/Spinner'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'

type Props = {
  title?: string
  className?: string
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
  buttonType?: ButtonType
  children?: React.ReactNode
}

const FormButton: React.FC<Props> = ({ title, className, type, onClick, buttonType, children }): JSX.Element => {
  const load = useSelector((state: RootState) => state.app.loading)
  const [loading, setLoading] = useState(load)

  useEffect(() => {
    setLoading(load)
  }, [load])
  const buttonClass =
    buttonType === ButtonType.Confirm
      ? 'bg-gradient-to-r from-[#FFB200] to-[#DC9A00] py-3 w-full mb-1.5'
      : buttonType === ButtonType.Cancel
        ? 'bg-red-500 hover:bg-red-600 py-3 w-full mb-1.5 '
        : buttonType === ButtonType.Disabled
          ? 'bg-gray-500 hover:bg-gray-600 py-3 w-full mb-1.5  '
          : buttonType === ButtonType.Deposit
            ? 'bg-[#01102c] rounded-2xl border border-[#344056] p-2 pl-4 pr-4 text-l'
            : buttonType === ButtonType.Dark
              ? 'focus:outline-none bg-gradient-to-r from-[#030e32] to-[#18243f] mb-0 h-full p-2 w-full mb-1.5 '
              : ''

  return (
    <button
      onClick={onClick}
      type={type ?? 'button'}
      className={`block w-full text-center text-white px-2 ${buttonClass} ${className}`}
      disabled={buttonType === ButtonType.Disabled || loading}
    >
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <IconSpinner />
        </div>
      ) : (
        <>
          {children}
          {title}
        </>
      )}
    </button>
  )
}

export default FormButton
