import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import closeIcon from '@/assets/icones/close-desktop.png'
import IconAccount from '@/components/atoms/Icons/Account'
import IconDeposit from '@/components/atoms/Icons/Deposit'
import IconInvite from '@/components/atoms/Icons/Invite'
import IconMoeda from '@/components/atoms/Icons/Moeda'
import IconSair from '@/components/atoms/Icons/Sair'
import IconSegurity from '@/components/atoms/Icons/Segurity'
import IconStar from '@/components/atoms/Icons/Star'
import IconSuport from '@/components/atoms/Icons/Suport'
import IconWallet from '@/components/atoms/Icons/Wallet'
import PartnerIcon from '@/components/atoms/Icons/Partner'

import IconUser from '@/assets/icones/user.png'
import IconFireTop from '@/assets/side-bar/fire-top-left.png'
import IconFireBottom from '@/assets/side-bar/fire-bottom-right.png'
import IconDivisionEmbreve from '@/assets/side-bar/embreve.png'
import IconDivisionLogoSideBar from '@/assets/side-bar/logo-side-bar.png'
import IconBrasil from '@/assets/icones/icon-brasil.png'
import simpleLogo from '@/assets/logos/new_logo.webp'
import IconTrophy from '@/assets/icones/icon-trophy.png'
import IconRecompensas from '@/assets/icones/side-bar-recompensas.png'
import IconMickey from '@/assets/icones/side-bar-mickey.png'
import IconHelloKitty from '@/assets/icones/side-bar-hello-kitty.png'
import IconExclusivos from '@/assets/icones/side-bar-exclusivos.png'
import IconSlots from '@/assets/icones/side-bar-slots.png'
import IconRanking from '@/assets/icones/side-bar-ranking.png'
import IconKungFu from '@/assets/icones/side-bar-kung-fu.png'
import IconLula from '@/assets/icones/side-bar-lula.png'
import IconBolsonaro from '@/assets/icones/side-bar-bolsonaro.png'
import IconDamas from '@/assets/icones/side-bar-damas.png'
import IconJujutsu from '@/assets/icones/side-bar-jujutsu.png'
import IconDragonBall from '@/assets/icones/side-bar-dragon-ball.png'
import IconXadrez from '@/assets/icones/side-bar-xadrez.png'
// import walletIcon from '@/assets/icones/walletIcon.png'
import { kify } from '@/lib/currency'
import useTranslate from '@/service/i18n.hook'
import { setAppData } from '@/store/app/actions'
import { WalletState } from '@/store/wallet/state'
import { Tooltip } from 'react-tooltip'
import Footer from '../Footer'
import MobileNavbar from '../MobileNavbar'
import { useHeader } from '../HeaderContext'
import { useGameContext } from '@/context/GameContext'
import searchButtonIcone from '@/assets/icones/search.png'
import ModalSearch from './ModalSearch'
import { RootState } from '@/store/store'
import useApiService from '@/service/api.hook'

interface LinkDrop {
  id: number
  title: string
  url?: string
  image?: string
  visible?: boolean
  action?: () => void
  icon?: React.ReactNode
}

const CustomHeader: React.FC = (): JSX.Element => {
  console.log('Rendering CustomHeader component')

  const userMenuRef = useRef<HTMLDivElement>(null)
  const modalRef = useRef<HTMLDivElement>(null) // Reference for the modal

  const { isDropdownOpen, toggleDropdown, closeDropdown } = useHeader()
  console.log('Dropdown open:', isDropdownOpen)

  const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false)
  console.log('User menu open:', isUserMenuOpen)

  const [walletValue, setWalletValue] = useState<WalletState>({} as WalletState)
  console.log('Initial wallet value:', walletValue)

  const { translateMessage, messages } = useTranslate()
  const { signOut, updateUserData } = useApiService()
  const dispatch = useDispatch()
  const authenticatedRedux = useSelector((state: RootState) => state.auth.authenticated)
  const ruleRedux = useSelector((state: RootState) => state.user.ruleId)
  const walletBalanceRedux = useSelector((state: RootState) => state.wallet)
  const location = useLocation()
  const shouldRenderContent = !location.pathname.startsWith('/policy')
  console.log('Should render content:', shouldRenderContent)

  const [searchTerm, setSearchTerm] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [rule, setRule] = useState<number>(ruleRedux)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(authenticatedRedux)
  const { gameCards } = useGameContext()

  console.log('Initial authenticated state:', isAuthenticated)
  console.log('Initial rule state:', rule)

  const filteredData = gameCards
    .filter((game) => game.title.toLowerCase().includes(searchTerm.toLowerCase()))
    .map((game) => ({
      url: game.url,
      title: game.title,
      game_url: game.gameUrlBcash || '',
    }))

  console.log('Filtered data:', filteredData)

  useEffect(() => {
    console.log('Wallet balance redux changed:', walletBalanceRedux)
    setWalletValue(walletBalanceRedux)
  }, [walletBalanceRedux])

  useEffect(() => {
    console.log('Authenticated redux changed:', authenticatedRedux)
    setIsAuthenticated(authenticatedRedux)
  }, [authenticatedRedux])

  useEffect(() => {
    console.log('Rule redux changed:', ruleRedux)
    setRule(ruleRedux)
  }, [ruleRedux])

  const updateUserWallet = useCallback(() => {
    console.log('Running updateUserWallet')
    if (isAuthenticated) {
      console.log('User is authenticated, updating user data')
      updateUserData()
    }
  }, [isAuthenticated, updateUserData])

  useEffect(() => {
    console.log('Setting interval to update user wallet every 60 seconds')
    const intervalId = setInterval(updateUserWallet, 60000)
    return () => {
      console.log('Clearing interval for updating user wallet')
      clearInterval(intervalId)
    }
  }, [updateUserWallet])

  useEffect(() => {
    console.log('Location pathname changed, scrolling to top')
    window.scrollTo(0, 0)
  }, [location.pathname])

  const navigate = useNavigate()

  const openModalSignUp = () => {
    console.log('Opening sign-up modal')
    dispatch(setAppData({ modalSignUp: true }))
  }

  const openModalSignIn = () => {
    console.log('Opening sign-in modal')
    dispatch(setAppData({ modalSignIn: true }))
  }

  const exitApp = useCallback(async () => {
    console.log('Exiting app')
    try {
      await signOut()
    } catch (error) {
      console.log('Error during logout:', error)
    }
  }, [signOut])

  const menuLogoSideBar: LinkDrop[] = [
    { id: 1, title: 'Originais Bcash', url: '/', image: IconExclusivos },
    { id: 2, title: 'Kung Fu Fortune', url: '/gaming/kung-fu-panda-fortune', image: IconKungFu },
    { id: 3, title: 'Lula Fortune', url: '/gaming/lula-fortune', image: IconLula },
    { id: 4, title: 'Bolsonaro Fortune', url: '/gaming/bolsonaro-fortune', image: IconBolsonaro },
    { id: 5, title: 'Mickey Mouse Fortune', url: '/gaming/mickey-mouse-fortune', image: IconMickey },
    { id: 6, title: 'Hello Kitty Fortune', url: '/gaming/hello-kitty-fortune', image: IconHelloKitty },
    { id: 7, title: 'Recompensas', url: '/account/partner', image: IconRecompensas },
    { id: 8, title: 'Slots', url: '/search', image: IconSlots },
    { id: 9, title: 'Ranking', url: '/ranking', image: IconRanking },
  ]

  console.log('Menu Logo Side Bar:', menuLogoSideBar)

  const menusLogados: LinkDrop[] = [
    {
      id: 1,
      title: 'Conta',
      url: '/account/info',
      icon: <IconAccount />,
      visible: isAuthenticated,
    },
    {
      id: 2,
      title: 'Depositar',
      url: '/account/deposit',
      icon: <IconDeposit />,
      visible: isAuthenticated,
    },
    {
      id: 3,
      title: 'Carteira',
      url: '/account/wallet',
      icon: <IconWallet />,
      visible: isAuthenticated,
    },
    {
      id: 4,
      title: 'Ranking',
      url: '/ranking',
      icon: <img src={IconTrophy} alt="Ranking" className="w-5 h-5" />,
      visible: isAuthenticated,
    },
    {
      id: 5,
      title: 'Convide e ganhe',
      url: '/invite',
      icon: <IconInvite />,
    },
    {
      id: 6,
      title: 'Políticas e Segurança',
      url: '/policy/service',
      icon: <IconSegurity />,
      visible: true,
    },
    {
      id: 7,
      title: 'Suporte',
      url: '/account/support',
      icon: <IconSuport />,
      visible: isAuthenticated,
    },
    {
      id: 8,
      title: rule === parseInt(import.meta.env.VITE_INFLUENCER_RULE_ID, 10) ? 'Àrea de parceiros' : 'Convide e Ganhe',
      url: '/account/partner',
      icon: <PartnerIcon />,
      visible: isAuthenticated,
    },
    {
      id: 9,
      title: 'Sair',
      action: () => {
        exitApp()
      },
      icon: <IconSair />,
      visible: isAuthenticated,
    },
  ]

  console.log('Menus Logados:', menusLogados)

  const menuEmbreve: LinkDrop[] = [
    { id: 1, title: 'Damas Online', url: '/online-checkers', image: IconDamas },
    { id: 2, title: 'Jujutsu Fortune', url: '/jujutsu-fortune', image: IconJujutsu },
    { id: 3, title: 'Dragon Ball Fortune', url: '/dragon-ball-fortune', image: IconDragonBall },
    { id: 4, title: 'Xadrez Online', url: '/online-chess', image: IconXadrez },
  ]

  console.log('Menu Em Breve:', menuEmbreve)

  const redirectUser = (path: string): void => {
    console.log('Redirecting user to:', path)
    if (path.includes('account')) {
      updateUserData()
    }
    navigate(path)
    setIsUserMenuOpen(false)
    window.scrollTo(0, 0)
  }

  const handleMenuClick = (url?: string, action?: () => void) => {
    console.log('handleMenuClick called with:', { url, action })
    if (url) {
      console.log('URL provided:', url)
      redirectUser(url)
    } else if (action) {
      console.log('Action provided')
      action()
    }
    closeDropdown()
  }

  const toggleUserMenu = () => {
    console.log('Toggling user menu')
    setIsUserMenuOpen(!isUserMenuOpen)
  }

  const formatDate = (dateInput: string | Date): string => {
    const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`
    console.log('Formatted date:', formattedDate)
    return formattedDate
  }

  return (
    <div className="flex">
      <nav
        className={`fixed top-0 left-0 h-full ${
          isDropdownOpen ? 'w-full lg:w-64' : 'w-0 lg:w-20'
        } transition-all duration-300 transform bg-[#01102C] mt-0 sm:mt-10 overflow-y-auto`}
      >
        <style>
          {`
      nav::-webkit-scrollbar {
        width: 12px;
      }
      nav::-webkit-scrollbar-thumb {
        background-color: #01102C;
        border-radius: 10px;
        border: 3px solid #01102C;
      }
      nav::-webkit-scrollbar-track {
        background: #01102C;
      }
    `}
        </style>
        <div className="h-[90%] flex flex-col">
          <div className="flex justify-between items-start">
            <img src={IconFireTop} alt="Fire Top" className="w-auto h-12" />
            <div></div>
            <img
              src={closeIcon}
              alt="Fire Top"
              className="w-auto h-7 m-3 block lg:hidden cursor-pointer"
              onClick={closeDropdown}
            />
          </div>

          <div className="w-full">
            <img src={IconDivisionLogoSideBar} alt="Logo Side Bar" className="w-full h-auto my-2" />
          </div>

          <ul className="pt-1 pb-2 sm:pl-1 sm:py-1 flex flex-col sm:block flex-grow">
            <li className="m-2 lg:hidden">
              <div className="relative w-full" ref={modalRef}>
                <input
                  className="w-full p-2 pl-12 pr-12 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 text-white bg-[#000918]"
                  placeholder="Encontrar um Jogo"
                  onClick={(e) => e.stopPropagation()}
                  type="text"
                  value={searchTerm}
                  onChange={(e) => {
                    console.log('Search term changed:', e.target.value)
                    setSearchTerm(e.target.value)
                    setIsModalOpen(e.target.value !== '') // Abre o modal apenas se o campo não estiver vazio
                  }}
                />
                <div className="absolute inset-y-0 left-0 flex items-center pl-1 cursor-pointer">
                  <img src={searchButtonIcone} alt="Search Icon" className="h-8 w-8" />
                </div>
                {searchTerm && (
                  <div
                    className="absolute inset-y-0 right-0 flex items-center pr-1 cursor-pointer"
                    onClick={() => {
                      console.log('Clearing search term and closing modal')
                      setSearchTerm('') // Limpar o campo de busca
                      setIsModalOpen(false) // Fechar o modal
                    }}
                  >
                    <img src={closeIcon} alt="Close Icon" className="h-8 w-8" />
                  </div>
                )}
              </div>
            </li>
            {menuLogoSideBar.map(
              (linkDrop) =>
                linkDrop.visible !== false && (
                  <li key={linkDrop.id} className="uppercase">
                    <button
                      onClick={() => handleMenuClick(linkDrop.url, linkDrop.action)}
                      className="m-2 font-semibold hover:bg-[#021130] sm:hover:scale-110 px-4 py-2 rounded sm:py-2 sm:m-1"
                    >
                      <div className="flex flex-row gap-2 items-center">
                        {linkDrop.icon ||
                          (linkDrop.image && <img src={linkDrop.image} alt={linkDrop.title} className="w-5 h-5" />)}
                        <span className={`${isDropdownOpen ? 'inline' : 'hidden'}`} style={{ fontSize: '14px' }}>
                          {linkDrop.title}
                        </span>
                      </div>
                    </button>
                  </li>
                )
            )}
          </ul>

          <div className="w-full">
            <img src={IconDivisionEmbreve} alt="Em breve" className="w-full h-auto my-2" />
          </div>

          <ul className="pt-1 pb-2 sm:pl-1 sm:py-1 flex flex-col sm:block flex-grow opacity-50">
            {menuEmbreve.map((linkDrop) => (
              <li key={linkDrop.id} className="uppercase">
                <button
                  onClick={() => handleMenuClick(linkDrop.url)}
                  className="m-2 font-semibold hover:bg-[#021130] sm:hover:scale-110 px-4 py-2 rounded sm:py-2 sm:m-1"
                >
                  <div className="flex flex-row gap-2 items-center">
                    {linkDrop.icon ||
                      (linkDrop.image && <img src={linkDrop.image} alt={linkDrop.title} className="w-5 h-5" />)}
                    <span className={`${isDropdownOpen ? 'inline' : 'hidden'}`} style={{ fontSize: '14px' }}>
                      {linkDrop.title}
                    </span>
                  </div>
                </button>
              </li>
            ))}
          </ul>
          <div className="flex justify-end items-end mt-auto">
            <img src={IconFireBottom} alt="Fire Bottom" className="w-auto h-12" />
          </div>
        </div>
      </nav>

      <nav
        ref={userMenuRef}
        className={`fixed right-0 top-0 mt-16 w-full lg:w-80 lg:mr-[30px] lg:mt-12 bg-cover bg-no-repeat bg-center bg-opacity-50 lg:rounded-2xl bg-gradient-to-l from-blue-950 via-[#091228] to-[#091231] z-50 ${
          isUserMenuOpen ? 'block' : 'hidden'
        }`}
      >
        <ul className="pt-1 pb-2 sm:pl-1 sm:py-1 flex flex-col sm:block">
          {menusLogados.map((linkDrop) => (
            <li key={linkDrop.id} className={` ${linkDrop.visible ? '' : 'hidden'}`}>
              {linkDrop.url && !linkDrop.action ? (
                <button
                  onClick={() => handleMenuClick(linkDrop.url)}
                  className="m-2 font-semibold hover:border-solid hover:border-2 hover:box-border hover:border-green-400 sm:hover:border-none sm:inline-block sm:hover:scale-110 px-4 py-2 rounded sm:py-2 sm:m-1"
                >
                  <div className="flex flex-row gap-2 items-center">
                    {linkDrop.icon}
                    <span>{linkDrop.title}</span>
                  </div>
                </button>
              ) : (
                <button
                  onClick={() => handleMenuClick(undefined, linkDrop.action)}
                  className="m-2 font-semibold hover:border-solid hover:border-2 hover:box-border hover:border-green-400 sm:hover:border-none sm:inline-block sm:hover:scale-110 px-4 py-2 rounded sm:py-2 sm:m-1"
                >
                  <div className="flex flex-row gap-2 items-center">
                    {linkDrop.icon}
                    <span>{linkDrop.title}</span>
                  </div>
                </button>
              )}
            </li>
          ))}
        </ul>
      </nav>
      <div className={`flex-1 transition-all duration-300 ${isDropdownOpen ? 'ml-[30rem] lg:ml-64' : 'ml-0 lg:ml-20'}`}>
        <header
          className={`fixed top-0 left-0 right-0 shadow-2xl z-10 border-[1px] border-blue-300/30 bg-blue-950 sm:px-0 sm:py-0 ${
            isDropdownOpen ? 'hidden lg:block' : ''
          }`}
        >
          <section className="flex justify-between items-center sm:h-20 lg:h-10 h-16 px-3">
            <div className="flex items-center">
              <button
                type="button"
                onClick={toggleDropdown}
                className="menu-trigger focus:outline-none hover:scale-110 focus:scale-110 rounded-md transition duration-300 ease-in-out relative mr-4 hidden sm:block"
              >
                <svg
                  id="Camada_1"
                  data-name="Camada 1"
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 64 64"
                  className={`w-7 h-7 ${isDropdownOpen ? 'text-green-01' : 'text-white'}`}
                >
                  <rect className="cls-1" x="6.41" y="9.14" width="51.18" height="8.53" />
                  <rect className="cls-1" x="6.41" y="27.73" width="51.18" height="8.53" />
                  <rect className="cls-1" x="6.41" y="46.33" width="51.18" height="8.53" />
                </svg>
              </button>
              <Link to={'/'}>
                <div className="flex items-center">
                  <img className="w-11 h-9" src={simpleLogo} alt="Erro" height={64} width={64} />
                  <div className="hidden lg:block font-bold ml-4">
                    <span>Bcash.bet</span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="flex flex-row items-center">
              <div>
                <ul className="flex items-center gap-4 font-bold text-sm lg:text-base">
                  {isAuthenticated ? (
                    <>
                      <li data-tooltip-id="wallet-tooltip">
                        <Link to="/account/wallet" className="hover:opacity-90 transition-all h-[34px]">
                          <div className="flex px-3 py-1 gap-2 border border-orange-300 rounded-md items-center justify-between">
                            <div className="w-[18px] h-[18px]">
                              <IconMoeda />
                            </div>
                            <span>R$ {kify(walletValue.balance + walletValue.virtualBalance)}</span>
                          </div>
                        </Link>
                      </li>
                      <Tooltip id="wallet-tooltip">
                        <div className="flex flex-col items-baseline justify-center font-normal">
                          <span>
                            Saldo: <b>R$ {kify(walletValue.balance)}</b>
                          </span>
                          <span>
                            Bônus: <b>R$ {kify(walletValue.virtualBalance)}</b>
                          </span>
                          {walletValue.updateDate && (
                            <span className="text-xs">
                              Atualizado em <b className="text-red-600">{formatDate(walletValue.updateDate)}</b>
                            </span>
                          )}
                        </div>
                      </Tooltip>
                      <li>
                        <Link to="/account/deposit" className="hover:opacity-90 transition-all">
                          <div className="flex px-4 py-1 gap-2 border  bg-[#00B900] rounded-md items-center justify-center">
                            <span>Depositar</span>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <div className="flex items-center gap-2 cursor-pointer" onClick={toggleUserMenu}>
                          <img src={IconUser} alt="User" style={{ width: '16px' }} />
                        </div>
                      </li>
                    </>
                  ) : (
                    <>
                      <li onClick={() => openModalSignIn()} style={{ marginLeft: '20px' }}>
                        <span className="font-medium text-xs hover:opacity-90 transition-all cursor-pointer">
                          {translateMessage(messages.customHeader_signin)}
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={() => openModalSignUp()}
                          className="bg-yellow-600 lg:bg-transparent border border-orange-300 font-normal lg:font-medium text-xs lg:hover:opacity-90 py-3 px-4 lg:py-2 lg:px-4 rounded-full transition-all whitespace-nowrap cursor-pointer"
                        >
                          {translateMessage(messages.customHeader_signup)}
                        </span>
                      </li>
                      <li className="lg:block hidden flex items-center gap-2">
                        <Link to="/account/premium">
                          <div className="flex px-2 py-1 gap-1 bg-yellow-600 border border-orange-300 rounded-md items-center justify-center">
                            <div>
                              <IconStar />
                            </div>
                            <div>
                              <Link to="" className="font-medium text-xs hover:opacity-90">
                                {translateMessage(messages.customHeader_premium)}
                              </Link>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <div className="flex items-center gap-2 cursor-pointer" onClick={toggleUserMenu}>
                          <img src={IconUser} alt="User" className="hidden sm:block" style={{ width: '16px' }} />
                          <svg
                            id="Camada_1"
                            data-name="Camada 1"
                            fill="white"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 64 64"
                            className={`w-7 h-7 ${isDropdownOpen ? 'text-green-01' : 'text-white'} block sm:hidden`}
                          >
                            <rect className="cls-1" x="6.41" y="9.14" width="51.18" height="8.53" />
                            <rect className="cls-1" x="6.41" y="27.73" width="51.18" height="8.53" />
                            <rect className="cls-1" x="6.41" y="46.33" width="51.18" height="8.53" />
                          </svg>
                        </div>
                      </li>
                      <li className="hidden lg:flex">
                        <div className="flex items-center gap-2 cursor-pointer">
                          <img src={IconBrasil} alt="Brasil" className="w-6 h-6" />
                          <span style={{ fontWeight: '500', color: 'white', fontSize: '14px' }}>PT/BR</span>
                        </div>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </section>
        </header>
        {shouldRenderContent && (
          <main className="pt-[64px] min-h-screen">
            <Outlet />
            <Footer />
          </main>
        )}
      </div>
      <MobileNavbar />
      <ModalSearch isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} filteredData={filteredData} />
    </div>
  )
}

export default CustomHeader
