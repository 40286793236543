import React, { useEffect, useState } from 'react'
import SwiperCore from 'swiper'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useDispatch, useSelector } from 'react-redux'
import { setAppData } from '@/store/app/reducer'
import { useNavigate } from 'react-router-dom'

// Banners desktop
import banner1 from '@/assets/banner/banner-1-wide2.png'
import banner2 from '@/assets/banner/banner-2-wide.webp'
import banner3 from '@/assets/banner/banner-3-wide.webp'
import banner4 from '@/assets/banner/banner-4-wide2.jpg'

// banners mobile
import bannerMobi1 from '@/assets/banner/banner-1-wide2.png'
import bannerMobi2 from '@/assets/bannerMobile/banner-2.webp'
import bannerMobi3 from '@/assets/bannerMobile/banner-3.webp'
import bannerMobi4 from '@/assets/banner/banner-4-wide2.jpg'
import { RootState } from '@/store/store'

// Imagens para as setas de navegação
import leftArrow from '@/assets/icones/left-arrow.png'
import rightArrow from '@/assets/icones/right-arrow.png'

SwiperCore.use([Pagination, Autoplay, Navigation])

const Carousel: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const authenticatedRedux = useSelector((state: RootState) => state.auth.authenticated)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(authenticatedRedux)

  useEffect(() => {
    setIsAuthenticated(authenticatedRedux)
  }, [authenticatedRedux])

  const openModalBanner = (type: string) => {
    if (isAuthenticated) {
      dispatch(setAppData({ modalBanner: { status: true, type } }))
    } else {
      dispatch(setAppData({ modalSignIn: true }))
    }
  }

  const bonus1000 = () => {
    if (isAuthenticated) {
      navigate('/account/deposit')
    } else {
      dispatch(setAppData({ modalSignIn: true }))
    }
  }
  const bonusInauguration = () => {
    if (isAuthenticated) {
      navigate('/partner')
    } else {
      dispatch(setAppData({ modalSignIn: true }))
    }
  }

  return (
    <section className="relative w-full carousel md:px-20 md:h-full" id="carousel-container">
      <section className="w-full flex top-0 lg:px-4 lg:mt-8 ">
        <Swiper
          autoplay={{
            delay: 55000,
            disableOnInteraction: true,
          }}
          pagination={{ clickable: true }}
          centeredSlides={true}
          slidesPerView={1}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          modules={[Pagination, Navigation, Autoplay]}
          loop={true}
          breakpoints={{
            768: {
              slidesPerView: 1.1,
              navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              },
            },
          }}
        >
          <div className="swiper-button-prev">
            <img src={leftArrow} alt="Previous" />
          </div>
          <div className="swiper-button-next">
            <img src={rightArrow} alt="Next" />
          </div>
          <SwiperSlide onClick={() => openModalBanner('dobro')}>
            <div className="hidden md:block cursor-pointer">
              <img src={banner1} alt="banner" className="carousel-image" />
            </div>
            <div className="md:hidden block">
              <img src={bannerMobi1} alt="banner" className="w-full h-auto" />
            </div>
          </SwiperSlide>
          <SwiperSlide onClick={() => openModalBanner('premium')}>
            <div className="hidden md:block cursor-pointer">
              <img src={banner2} alt="banner" className="carousel-image" />
            </div>
            <div className="md:hidden block">
              <img src={bannerMobi2} alt="banner" className="w-full h-auto" />
            </div>
          </SwiperSlide>
          <SwiperSlide onClick={bonus1000}>
            <div className="hidden md:block cursor-pointer">
              <img src={banner3} alt="banner" className="carousel-image" />
            </div>
            <div className="md:hidden block">
              <img src={bannerMobi3} alt="banner" className="w-full h-auto" />
            </div>
          </SwiperSlide>
          <SwiperSlide onClick={bonusInauguration}>
            <div className="hidden md:block cursor-pointer">
              <img src={banner4} alt="banner" className="carousel-image" />
            </div>
            <div className="md:hidden block">
              <img src={bannerMobi4} alt="banner" className="w-full h-auto" />
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
    </section>
  )
}

export default Carousel
import './styles.css'
