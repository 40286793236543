import axios from 'axios'

const API_URL = import.meta.env.VITE_BACKEND_URL +'/app-data/recent_plays'

let isFetching = false

export const fetchData = async () => {
  if (isFetching) {
    console.warn('Fetch already in progress')
    return {
      recentPlays: [],
    }
  }

  isFetching = true

  try {
    const response = await axios.get(API_URL, {
      headers: {
        accept: '*/*',
        'accept-language': 'pt,en-US;q=0.9,en;q=0.8',
        priority: 'u=1, i',
      },
    })

    const recentPlays = response.data

    return {
      recentPlays,
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    return {
      recentPlays: [],
    }
  } finally {
    isFetching = false
  }
}
