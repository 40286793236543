export const staticPublicAppData = {
    "navbar": [
      {
        "icon_url": "",
        "path": "",
        "title": ""
      }
    ],
    "games_list": [
      {
        "id": 8,
        "isEnabled": true,
        "provider": "bcash",
        "uuid": "KUNG_FU_PANDA",
        "title": "Kung Fu Fortune",
        "gameType": "KUNG_FU_PANDA",
        "category": [
          "exclusivos",
          "populares"
        ],
        "banner_url": "/assets/games/bcash/banners/kungfu-fortune.webp",
        "banner_url_top_10": "/assets/games/top10/top-1.webp",
        "background_url": "/assets/games/bcash/backgrounds/bg-panda-original.webp",
        "game_url": "https://bcash-slotmachine-kungfu-vercel.vercel.app",
        "top": 1
      },
      {
        "id": 10,
        "isEnabled": true,
        "provider": "bcash",
        "uuid": "BOLSONARO",
        "title": "Bolsonaro Fortune",
        "gameType": "BOLSONARO",
        "category": [
          "exclusivos",
          "populares"
        ],
        "banner_url": "/assets/games/bcash/banners/bolsonaro-fortune.webp",
        "banner_url_top_10": "/assets/games/top10/top-5.webp",
        "background_url": "/assets/games/bcash/backgrounds/bolsonaro_background.webp",
        "game_url": "https://bcash-slotmachine-bolsonaro-vercel.vercel.app",
        "top": 5
      },
      {
        "id": 12,
        "isEnabled": true,
        "provider": "bcash",
        "uuid": "MICKEY_MOUSE",
        "title": "Mickey Mouse Fortune",
        "gameType": "MICKEY",
        "category": [
          "exclusivos",
          "populares"
        ],
        "banner_url": "/assets/games/bcash/banners/mickey-fortune.webp",
        "banner_url_top_10": "/assets/games/top10/top-2.webp",
        "background_url": "/assets/games/bcash/backgrounds/bg-mickey.webp",
        "game_url": "https://bcash-slotmachine-mickey-vercel.vercel.app",
        "top": 2
      },
      {
        "id": 16,
        "isEnabled": true,
        "provider": "bcash",
        "uuid": "HELLO_KITTY",
        "title": "Hello Kitty Fortune",
        "gameType": "HELLO_KITTY",
        "category": [
          "exclusivos",
          "populares"
        ],
        "banner_url": "/assets/games/bcash/banners/hello-kitty-fortune.webp",
        "banner_url_top_10": "/assets/games/top10/top-3.webp",
        "background_url": "/assets/games/bcash/backgrounds/bg-hello-kitty-original.webp",
        "game_url": "https://bcash-slotmachine-hellokitty-vercel.vercel.app",
        "top": 3
      },
      {
        "id": 11,
        "isEnabled": true,
        "provider": "bcash",
        "uuid": "LULA",
        "title": "Lula Fortune",
        "gameType": "LULA",
        "category": [
          "exclusivos",
          "populares"
        ],
        "banner_url": "/assets/games/bcash/banners/lula-fortune.webp",
        "banner_url_top_10": "/assets/games/top10/top-4.webp",
        "background_url": "/assets/games/bcash/backgrounds/lula_background.webp",
        "game_url": "https://bcash-slot-machine-lula.vercel.app",
        "top": 4
      }
    ]
  }