import React from 'react';
import ContainerGames from '@/components/molecules/ContainerGames';
import { useGameContext } from '@/context/GameContext';

const Games: React.FC = (): JSX.Element => {
  const { gameCards, newGameCards } = useGameContext();

  return (
    <section className="px-4">
      <ContainerGames titleOne="Originais Bcash" titleTwo="Top 10 Bcash" data={gameCards} newData={newGameCards} />
    </section>
  );
};

export default Games;
