import FormButton from '@/components/atoms/FormButton'
import IconUploadFile from '@/components/atoms/Icons/UploadFile'
import { ButtonType } from '@/interfaces/application/buttonType'
import useApiService from '@/service/api.hook'
import React, { useRef, useState } from 'react'
import { toast } from 'react-toastify'
enum UpdateDocumentEnum {
  PHOTO = 'photo',
  REAR_DOCUMENT = 'rearDocument',
  FRONT_DOCUMENT = 'frontDocument',
}

const ProofsPage: React.FC = (): JSX.Element => {
  const { updateUserDocument } = useApiService()
  const fileInputFront = useRef(null)
  const fileInputBack = useRef(null)
  const fileInputAddress = useRef(null)

  const [selectedFileNames, setSelectedFileNames] = useState({
    [UpdateDocumentEnum.FRONT_DOCUMENT]: '',
    [UpdateDocumentEnum.REAR_DOCUMENT]: '',
    [UpdateDocumentEnum.PHOTO]: '',
  })

  const handleFileInputChange = (type: UpdateDocumentEnum, fileInputRef: React.RefObject<HTMLInputElement>) => {
    const file = fileInputRef.current?.files?.[0]
    if (file) {
      setSelectedFileNames((prevNames) => ({
        ...prevNames,
        [type]: file.name,
      }))
    } else {
      setSelectedFileNames((prevNames) => ({
        ...prevNames,
        [type]: '',
      }))
    }
  }

  const handleSaveClick = async (type: UpdateDocumentEnum, fileInputRef: React.RefObject<HTMLInputElement>) => {
    const file = fileInputRef.current?.files?.[0]
    if (file) {
      await updateUserDocument(type, file)
        .then(() => {
          toast.success('Arquivo salvo!')
          setSelectedFileNames((prevNames) => ({
            ...prevNames,
            [type]: '',
          }))
        })
        .catch((err) => {
          console.error(err)
          toast.error('Houve um erro no upload dos arquivos, tente novamente mais tarde. pf!')
        })
    } else {
      toast.error('Selecione um arquivo para enviar')
    }
  }
  return (
    <section className="bg-pallet1  w-full p-4 rounded-xl border-pallet2 border">
      <div>
        <div>
          <h1 className="pb-1 text-xl font-medium">Informações de identidade</h1>
        </div>
        <div id="identidadeContainer" className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div id="upload-item" className="w-full upload-item flex justify-center items-center flex-col">
            <h3 className="w-full text-xs opacity-70 pb-2">Foto da frente da sua identidade</h3>
            <div className="h-[200px] w-full bg-gradient-to-b from-pallet8 to-pallet7  rounded-2xl p-[1px]">
              <div className="bg-[#01102c] rounded-2xl h-full">
                <div className="h-full w-full rounded-lg p-1 flex items-center justify-center">
                  <label
                    htmlFor="fileInput"
                    className="h-full w-full rounded-lg cursor-pointer flex items-center justify-center"
                  >
                    <IconUploadFile className="w-28 h-28" />
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    ref={fileInputFront}
                    className="hidden"
                    onChange={() => handleFileInputChange(UpdateDocumentEnum.FRONT_DOCUMENT, fileInputFront)}
                  />
                </div>
              </div>
            </div>
            {selectedFileNames[UpdateDocumentEnum.FRONT_DOCUMENT] && (
              <p className=" text-xs opacity-70">{selectedFileNames[UpdateDocumentEnum.FRONT_DOCUMENT]}</p>
            )}

            <div className="pt-4 flex justify-center lg:justify-start">
              <FormButton
                title="Salvar"
                type="submit"
                className="w-[90%] lg:w-[200px] uppercase font-medium rounded-full"
                buttonType={ButtonType.Confirm}
                onClick={() => handleSaveClick(UpdateDocumentEnum.FRONT_DOCUMENT, fileInputFront)}
              />
            </div>
          </div>
          <div id="upload-item" className="w-full upload-item flex justify-center items-center flex-col">
            <h3 className="w-full text-xs opacity-70 pb-2">Foto da verso da sua identidade</h3>
            <div className="h-[200px] w-full bg-gradient-to-b from-pallet8 to-pallet7 rounded-2xl p-[1px]">
              <div className="bg-[#01102c] rounded-2xl h-full">
                <div className="h-full w-full rounded-lg p-1 flex items-center justify-center">
                  <label
                    htmlFor="fileInput"
                    className="h-full w-full rounded-lg cursor-pointer flex items-center justify-center"
                  >
                    <IconUploadFile className="w-28 h-28" />
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    ref={fileInputBack}
                    className="hidden"
                    onChange={() => handleFileInputChange(UpdateDocumentEnum.REAR_DOCUMENT, fileInputBack)}
                  />
                </div>
              </div>
            </div>
            {selectedFileNames[UpdateDocumentEnum.REAR_DOCUMENT] && (
              <p className="text-xs opacity-70">{selectedFileNames[UpdateDocumentEnum.REAR_DOCUMENT]}</p>
            )}
            <div className="pt-4 flex justify-center lg:justify-start">
              <FormButton
                title="Salvar"
                type="submit"
                className="w-[90%] lg:w-[200px] uppercase font-medium rounded-full"
                buttonType={ButtonType.Confirm}
                onClick={() => handleSaveClick(UpdateDocumentEnum.REAR_DOCUMENT, fileInputBack)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 ">
        <div>
          <h1 className="pb-1 text-xl font-medium">Informações de Endereço</h1>
        </div>
        <div id="identidadeContainer" className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div id="upload-item" className="w-full  upload-item flex justify-center items-center flex-col">
            <h3 className="w-full text-xs opacity-70 pb-2">Foto do seu comprovante de endereço</h3>
            <div className="h-[200px] w-full bg-gradient-to-b from-pallet8 to-pallet7 rounded-2xl p-[1px]">
              <div className="bg-[#01102c] rounded-2xl h-full">
                <div className="h-full w-full rounded-lg p-1 flex items-center justify-center">
                  <label
                    htmlFor="fileInput"
                    className="h-full w-full rounded-lg cursor-pointer flex items-center justify-center"
                  >
                    <IconUploadFile className="w-28 h-28" />
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    ref={fileInputAddress}
                    className="hidden"
                    onChange={() => handleFileInputChange(UpdateDocumentEnum.PHOTO, fileInputAddress)}
                  />
                </div>
              </div>
            </div>
            {selectedFileNames[UpdateDocumentEnum.PHOTO] && (
              <p className="text-xs opacity-70">{selectedFileNames[UpdateDocumentEnum.PHOTO]}</p>
            )}
            <div className="pt-4 flex justify-center lg:justify-start">
              <FormButton
                title="Salvar"
                type="submit"
                className="w-[90%] lg:w-[200px] uppercase font-medium rounded-full"
                buttonType={ButtonType.Confirm}
                onClick={() => handleSaveClick(UpdateDocumentEnum.PHOTO, fileInputAddress)}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProofsPage
