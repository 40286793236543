import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback } from 'react'
import { fetchData } from '@/service/plays.hook'

interface RecentPlay {
  jogo: string
  usuario: string
  tempo: string
  valor: string
  multi: string
  pagamento: string
}

interface ApiRecentPlay {
  gameType: string
  bet: number
  prize: number
  multiple: number
  win: boolean
  createdAt: string
  user: {
    login: string
  }
}

interface DataContextType {
  recentPlays: RecentPlay[]
  startFetching: () => void
  stopFetching: () => void
}

const DataContext = createContext<DataContextType | undefined>(undefined)

export const useDataContext = () => {
  const context = useContext(DataContext)
  if (!context) {
    throw new Error('useDataContext must be used within a DataProvider')
  }
  return context
}

export const DataProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [recentPlays, setRecentPlays] = useState<RecentPlay[]>([])
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null)

  const fetchRecentPlaysData = useCallback(async () => {
    const { recentPlays } = await fetchData()

    const mappedRecentPlays = recentPlays.map((play: ApiRecentPlay) => ({
      jogo: play.gameType,
      usuario: play.user.login,
      tempo: new Date(play.createdAt).toLocaleTimeString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      }),
      valor: `R$ ${(play.bet/100).toFixed(2)}`,
      multi: `${play.multiple}x`,
      pagamento: play.win ? `R$ ${(play.prize/100).toFixed(2)}` : 'R$ 0.00',
    }))

    setRecentPlays(mappedRecentPlays)
  }, [])

  const startFetching = useCallback(() => {
    fetchRecentPlaysData()
    if (!intervalId) {
      const id = setInterval(fetchRecentPlaysData, 5000)
      setIntervalId(id)
    }
  }, [fetchRecentPlaysData, intervalId])

  const stopFetching = useCallback(() => {
    if (intervalId) {
      clearInterval(intervalId)
      setIntervalId(null)
    }
  }, [intervalId])

  useEffect(() => {
    return () => {
      stopFetching()
    }
  }, [stopFetching])

  return (
    <DataContext.Provider value={{ recentPlays, startFetching, stopFetching }}>
      {children}
    </DataContext.Provider>
  )
}
