import { ITranslateDictionary } from './interface'

const dictionaryPortuguese: Partial<ITranslateDictionary> = {
  customHeader_signin: {
    id: 'customHeader_signin',
    defaultMessage: 'INICIAR SESSÃO',
  },
  customHeader_signout: {
    id: 'customHeader_signout',
    defaultMessage: 'SAIR',
  },
  customHeader_signup: {
    id: 'customHeader_signup',
    defaultMessage: 'CADASTRE-SE',
  },
  customHeader_premium: {
    id: 'customHeader_premium',
    defaultMessage: 'ASSINAR O PREMIUM',
  },
  modalSignup_title: {
    id: 'modalSignup_title',
    defaultMessage: 'Crie sua conta Grátis',
  },
  modalSignup_subtitle: {
    id: 'modalSignup_subtitle',
    defaultMessage: 'Já tem uma conta?',
  },
  modalSignup_enter: {
    id: 'modalSignup_enter',
    defaultMessage: 'Entrar',
  },
}

export default dictionaryPortuguese
