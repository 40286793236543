import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
// import { useNavigate } from 'react-router-dom'
import ModalPix from './ModalPix'
import { RootState } from '@/store/store'
import { setAppData } from '@/store/app/actions'
import useApiService, { APIResponse } from '@/service/api.hook'
import FormButton from '@/components/atoms/FormButton'
import FormInput from '@/components/atoms/FormInput'
import IconPix from '@/components/atoms/Icons/Pix'
// import IconCard from '@/components/atoms/Icons/Card'
import walletIcon from '@/assets/icones/walletIcon.png'
import bannerRight from '@/assets/banner/bonus-1000-wallet.png'
import simboloCartoes from '@/assets/icones/simbolo-cartoes.png'
import iconLock from '@/assets/icones/icon-lock.png' // Importe a imagem do cadeado
import { ButtonType } from '@/interfaces/application/buttonType'
import { TCreditCardDeposit, TDepositWait } from '@/interfaces/application/depositType'
import { MIN_DEPOSIT_VALUE } from '@/configs'

enum TypePayment {
  Pix = 'Pix',
  Paypal = 'Paypal',
  Card = 'Card',
}

enum CardPaymentType {
  Credit = 'Credit',
  Debit = 'Debit',
}

const DepositPage: React.FC = (): JSX.Element => {
  const { depositCreditCard, depositPix, depositWait } = useApiService()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [timedOut, setTimedOut] = useState(false)
  // const navigate = useNavigate()
  // const [verifyEmailDate, setVerifyEmailDate] = useState<Date>()
  const verifyEmailDateRedux = useSelector((state: RootState) => state.user.emailVerifiedAt)
  const userCpf = useSelector((state: RootState) => state.user.cpf) // Recupera o CPF do estado Redux

  // Novo estado para o nome completo
  const [fullName, setFullName] = useState<string>('')

  useEffect(() => {
    // Verifica se há um nome completo armazenado no local storage
    const storedFullName = localStorage.getItem('fullName')
    if (storedFullName && storedFullName !== 'undefined' && storedFullName !== 'null') {
      setFullName(storedFullName)
    }

    // setVerifyEmailDate(verifyEmailDateRedux)
    console.log('Loading:', loading)
    console.log('TimedOut:', timedOut)
    // }, [verifyEmailDateRedux, setVerifyEmailDate, loading, timedOut])
  }, [verifyEmailDateRedux, loading, timedOut])

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const maxRetry = 10

  const schemaCreditCard = Yup.object().shape({
    userDocument: Yup.string().required('Campo obrigatório'),
    amount: Yup.string().required('Campo obrigatório'),
    cardHolder: Yup.string().required('Campo obrigatório'),
    cardNumber: Yup.string().required('Campo obrigatório'),
    cardExpiration: Yup.string().required('Campo obrigatório'),
    cardCVV: Yup.string().required('Campo obrigatório'),
    fullName: Yup.string().optional(),
  })

  const schemaPix = Yup.object().shape({
    userDocument: Yup.string().required('Campo obrigatório'),
    amount: Yup.string()
      .required('Campo obrigatório')
      .test(`greaterThan${MIN_DEPOSIT_VALUE}`, `O valor minímo de depósito é de ${MIN_DEPOSIT_VALUE} reais.`, (value) => {
        const numericValue = parseFloat(value.replace('R$', ''))
        const transacaoValida = numericValue >= MIN_DEPOSIT_VALUE
        if (!transacaoValida) {
          toast.warning(`Depósitos mínimo no valor de R$ ${MIN_DEPOSIT_VALUE}`)
        } else {
          //   toast.info('Aguarde, estamos processando sua solicitação!', { autoClose: 3000 })
          // console.log('toastId: ', toastId)
          // setToastId(toastId); // Armazena o toastId no contexto
        }
        return transacaoValida
      }),
    fullName: Yup.string().optional(),
  })

  const [selectedTypePayment, setSelectedTypePayment] = useState<TypePayment | null>(null)

  const [selectedCardPaymentType, setSelectedCardPaymentType] = useState<CardPaymentType>(CardPaymentType.Credit)

  const handleFormSubmit: SubmitHandler<TCreditCardDeposit> = async (data) => {
    console.log('Dados do formulário:', data)

    if (data.fullName) {
      localStorage.setItem('fullName', data.fullName)
    } else {
      data.fullName = localStorage.getItem('fullName') || ''
    }

    if (selectedTypePayment === TypePayment.Card) {
      const cardData = { ...data, cardPaymentType: selectedCardPaymentType }
      console.log('Enviando dados do cartão:', cardData)
      depositCreditCard(cardData).then((r) => {
        console.log('Resposta do depósito com cartão:', r)
      })
    }

    if (selectedTypePayment === TypePayment.Pix) {
      setLoading(true)
      const toastId = toast.info('Aguarde, estamos processando sua solicitação!', { autoClose: false })
      console.log('Enviando dados do depósito Pix:', data)
      depositPix({
        ...data,
        amount: Number(data.amount.replace('R$', '')) * 100,
      })
        .then((resp: APIResponse<TDepositWait>) => {
          console.log('Resposta do depósito Pix:', resp)
          if (resp?.data && resp?.data?.id) {
            pixAwait(resp.data.id, toastId)
          }
        })
        .catch((err) => {
          console.log('Erro no depósito Pix:', err)
          toast.dismiss(toastId)
          setLoading(false)
          setTimedOut(true)
        })
    }
  }

  const {
    register: registerCreditCard,
    handleSubmit: handleSubmitCreditCard,
    formState: { errors: errosCreditCard },
    // control: controlCreditCard,
  } = useForm<TCreditCardDeposit>({
    defaultValues: {
      userDocument: '999.999.999-99',
      amount: '0',
      cardHolder: 'Nome do titúlar do cartão',
      cardNumber: '0000 0000 0000 0000',
      cardExpiration: '01/2000',
      cardCVV: '000',
    },
    resolver: yupResolver(schemaCreditCard),
  })

  const {
    register: registerPix,
    handleSubmit: handleSubmitPix,
    formState: { errors: errosPix },
  } = useForm<any>({
    defaultValues: {
      userDocument: userCpf || '', // Define o valor inicial do CPF com o valor recuperado do estado Redux
      amount: '',
      fullName: fullName || '', // Define o valor inicial do Nome Completo do local storage
    },
    resolver: yupResolver(schemaPix),
  })

  useEffect(() => {
    console.log('Valor inicial de userDocument:', userCpf || '')
    console.log('Valor inicial de fullName:', fullName || '')
  }, [userCpf, fullName])

  useEffect(() => {
    if (Object.keys(errosPix).length > 0) {
      console.log('Erros do formulário Pix:', errosPix)
      for (const [key, value] of Object.entries(errosPix)) {
        console.log(`Erro no campo ${key}:`, value)
      }
    }
  }, [errosPix])

  const pixAwait = async (id: string, toastId: React.ReactText, retries = 0) => {
    console.log(`Pix await attempt ${retries} for ID:`, id)
    const pixResponse = await depositWait(id)
    if (pixResponse.data && pixResponse.data.qrCodeLink !== null) {
      console.log('QR Code link received:', pixResponse.data.qrCodeLink)
      dispatch(setAppData({ modalPix: { status: true, data: pixResponse.data } }))
      toast.dismiss(toastId)

      toast.success('Seu PIX foi gerado com sucesso, efetue o pagamento!', { autoClose: 3000 })
      setLoading(false)
      return
    } else {
      if (retries >= maxRetry) {
        console.log('Max retries reached for Pix await')
        setTimedOut(true)
        setLoading(false)
        return
      }
      console.log('Await to retry', retries)
      return setTimeout(() => pixAwait(id, toastId, retries + 1), 6000)
    }
  }

  return (
    <div className="flex flex-col lg:flex-row gap-5">
      <div className="flex-1">
        <div className="topo flex gap-5">
          <div className="bg-pallet1 w-full p-4 rounded-xl border-pallet2 border pb-8">
            <div className="flex items-center gap-4">
              <h1 className="pb-1 text-xl font-medium text-white mb-2">Depositar</h1>
            </div>

            <div className="lg:text-left text-center gap-5 items-center">
              <h1 className="pb-1 text-l font-small">Escolha um método de pagamento:</h1>
            </div>
            <div className="flex lg:justify-start justify-center gap-4 pt-4 ">
              <button
                onClick={() => setSelectedTypePayment(TypePayment.Pix)}
                className={`flex items-center rounded-md justify-center h-[38px] w-[150px] max-w-[150px] ${
                  selectedTypePayment === TypePayment.Pix
                    ? ' bg-[#0059D5] border-[#002cb3] text-white'
                    : 'bg-[#000B24] border-[#404D5E]'
                } border p-6 font-semibold`}
              >
                <div className="mr-1">
                  <IconPix />
                </div>
                PIX
              </button>

              {/* <button
                onClick={() => setSelectedTypePayment(TypePayment.Card)}
                className={`flex items-center rounded-md justify-center h-[38px] w-[150px] max-w-[150px] ${
                  selectedTypePayment === TypePayment.Card
                    ? 'bg-[#0059D5] border-[#002cb3] text-white'
                    : 'bg-[#000B24] border-[#404D5E]'
                } border p-6 font-semibold`}
              >
                <div className="mr-1">
                  <IconCard />
                </div>
                CARTÃO
              </button> */}
            </div>
          </div>
          <div className="hidden lg:block lg:w-[45%]">
            <img src={bannerRight} />
          </div>
        </div>
        <div className="sessaoDados">
          {selectedTypePayment === TypePayment.Pix && (
            <form
              className="bg-pallet1 w-full p-4 rounded-xl border-pallet2 border pb-8 mt-4"
              onSubmit={handleSubmitPix(handleFormSubmit)}
            >
              <h2 className="text-l font-medium mb-4">Pagamento Pix Bancário</h2>
              <div className="grid gap-4">
                <FormInput
                  register={registerPix}
                  errors={errosPix}
                  required
                  type="text"
                  name="userDocument"
                  placeholder="Número de CPF"
                  label="Número de CPF"
                  value={userCpf} // Define o valor do CPF no campo
                  readOnly // Torna o campo somente leitura
                  icon={<img src={iconLock} alt="Lock Icon" className="h-6 w-6" />} // Adiciona o ícone do cadeado
                />
                <FormInput
                  register={registerPix}
                  errors={errosPix}
                  required
                  type="text"
                  name="fullName"
                  placeholder="Nome Completo"
                  label="Nome Completo"
                  value={fullName} // Define o valor do Nome Completo no campo
                  readOnly={!!fullName} // Torna o campo somente leitura se já houver um nome completo armazenado
                  icon={fullName ? <img src={iconLock} alt="Lock Icon" className="h-6 w-6" /> : undefined} // Adiciona o ícone do cadeado somente se houver nome completo
                />
                <h3 className="text-l font-medium mt-4">Escolha a quantia desejada:</h3>
                <div className="flex flex-col lg:flex-row lg:items-end lg:gap-4 w-full lg:max-w-[65%]">
                  <FormInput
                    register={registerPix}
                    errors={errosCreditCard}
                    required
                    type="text"
                    name="amount"
                    formatType="money-brl"
                    placeholder="Exemplo: R$200,00"
                    label="Quantia desejada"
                    className="flex-grow"
                  />
                  <FormButton
                    title="Depositar"
                    type="submit"
                    className="flex items-center rounded-md justify-center h-[30px] w-full lg:w-auto bg-gradient-to-r from-[#00C200] to-[#009100] border border-[#404D5E] p-[18px] font-semibold mt-4 lg:mt-0"
                    buttonType={ButtonType.Deposit}
                  >
                    <div className="mr-1">
                      <img src={walletIcon} style={{ height: '20px' }} />
                    </div>
                  </FormButton>
                </div>
              </div>
            </form>
          )}
          {selectedTypePayment === TypePayment.Card && (
            <form
              className="bg-pallet1 w-full p-4 rounded-xl border-pallet2 border pb-8 mt-4"
              onSubmit={handleSubmitCreditCard(handleFormSubmit)}
            >
              <div className="flex flex-col lg:flex-row lg:items-center lg:gap-4 mb-4">
                <h2 className="text-l font-medium mb-4 lg:mb-0">Pagamento Cartão de Crédito ou Débito</h2>
                <img
                  src={simboloCartoes}
                  className="lg:ml-1.5 lg:mb-0 lg:mt-0 lg:mr-0"
                  style={isMobile ? { width: '280px', height: 'auto', margin: 'auto' } : { height: '15px', margin: '0px' }}
                  alt="Símbolo Cartões"
                />
              </div>

              <div className="grid gap-4">
                <FormInput
                  register={registerCreditCard}
                  errors={errosCreditCard}
                  required
                  type="text"
                  name="cardNumber"
                  formatType="card"
                  maxLength={19}
                  placeholder="0000 0000 0000 0000"
                  label="Número do cartão"
                />
                <div className="flex gap-4">
                  <FormInput
                    register={registerCreditCard}
                    errors={errosCreditCard}
                    required
                    type="text"
                    name="cardExpiration"
                    formatType="date:MM/yyyy"
                    placeholder="10/2025"
                    maxLength={7}
                    label="Data de validade"
                    className="w-1/2"
                  />
                  <FormInput
                    register={registerCreditCard}
                    errors={errosCreditCard}
                    required
                    type="text"
                    name="cardCVV"
                    formatType="number"
                    maxLength={3}
                    placeholder="999"
                    label="CVV"
                    className="w-1/2"
                  />
                </div>
                <FormInput
                  register={registerCreditCard}
                  errors={errosCreditCard}
                  required
                  type="text"
                  name="cardHolder"
                  placeholder="Nome"
                  label="Nome"
                />
                <h3 className="text-l font-medium mt-4">Escolha sua forma de pagamento:</h3>

                <div className="flex lg:justify-start justify-center gap-4 pt-4 ">
                  <button
                    type="button"
                    onClick={() => setSelectedCardPaymentType(CardPaymentType.Credit)}
                    className={`flex items-center rounded-md justify-center h-[38px] w-[150px] max-w-[150px] ${
                      selectedCardPaymentType === CardPaymentType.Credit
                        ? ' bg-[#0059D5] border-[#002cb3] text-white'
                        : 'bg-[#000B24] border-[#404D5E]'
                    } border p-6 font-semibold`}
                  >
                    CRÉDITO
                  </button>

                  <button
                    type="button"
                    onClick={() => setSelectedCardPaymentType(CardPaymentType.Debit)}
                    className={`flex items-center rounded-md justify-center h-[38px] w-[150px] max-w-[150px] ${
                      selectedCardPaymentType === CardPaymentType.Debit
                        ? 'bg-[#0059D5] border-[#002cb3] text-white'
                        : 'bg-[#000B24] border-[#404D5E]'
                    } border p-6 font-semibold`}
                  >
                    DÉBITO
                  </button>
                </div>

                <div className="mt-4">
                  <h3 className="text-l font-medium">Escolha quantia desejada:</h3>
                  <div className="flex flex-col lg:flex-row lg:items-end lg:gap-4 w-full lg:max-w-[65%]">
                    <FormInput
                      register={registerCreditCard}
                      errors={errosCreditCard}
                      required
                      type="text"
                      name="amount"
                      formatType="money-brl"
                      placeholder="Exemplo: R$200,00"
                      label="Quantia desejada"
                      className="flex-grow"
                    />
                    <FormButton
                      title="Depositar"
                      type="submit"
                      className="flex items-center rounded-md justify-center h-[30px] w-full lg:w-auto bg-gradient-to-r from-[#00C200] to-[#009100] border border-[#404D5E] p-[18px] font-semibold mt-4 lg:mt-0"
                      buttonType={ButtonType.Deposit}
                    >
                      <div className="mr-1">
                        <img src={walletIcon} style={{ height: '20px' }} />
                      </div>
                    </FormButton>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
        <ModalPix />
      </div>

      <div className="lg:hidden pt-4">
        <img src={bannerRight} />
      </div>
    </div>
  )
}

export default DepositPage
