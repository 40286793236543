import LayoutAnimation from '@/components/atoms/LayoutAnimation'
import Carousel from '@/components/molecules/Carousel'
import GreenFlow from '@/components/molecules/GreenFlow'
import Games from '@/components/organisms/Games'
import TableLiveBetting from '@/components/organisms/TableLiveBetting'
import useApiService from '@/service/api.hook'
import { setAppData } from '@/store/app/reducer'
import { RootState } from '@/store/store'
import { AnimatePresence } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const HomePage: React.FC = (): JSX.Element => {
  const apiUrl = import.meta.env.VITE_BACKEND_URL
  console.log('apiUrl: ', apiUrl)

  const { search, pathname } = useLocation()
  const { verifyEmail } = useApiService()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = new URLSearchParams(search)
  const inviteCode = params.get('invite') as string
  const authenticatedRedux = useSelector((state: RootState) => state.auth.authenticated)
  const [checkingEmail, setCheckingEmail] = useState<boolean>(false)
  const [urlPath, setUrlPath] = useState<string>('/')
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(authenticatedRedux)
  useEffect(() => {
    setIsAuthenticated(authenticatedRedux)
  }, [authenticatedRedux])

  if (inviteCode && inviteCode.length > 0) {
    localStorage.setItem('inviteCode', inviteCode)
  }

  useEffect(() => {
    if (pathname !== urlPath) {
      setUrlPath(pathname)
      if (pathname === '/users/reset-password') {
        dispatch(setAppData({ modalRecoveryPassword: true }))
      }

      if (pathname === '/verify' && !checkingEmail) {
        const param = new URLSearchParams(search)
        setCheckingEmail(true)
        const token = param.get('token') as string
        verifyEmail(token)
          .then((result) => {
            setCheckingEmail(false)
            if (!result.error) {
              navigate('/')
              toast.success('Email verificado com sucesso!!')
            } else {
              if (isAuthenticated) {
                navigate('/account/info')
              } else {
                dispatch(setAppData({ modalSignIn: true }))
              }
              toast.error('Falha na verificação de email')
            }
            setCheckingEmail(false)
          })
          .catch((error) => {
            console.error(error)
            if (isAuthenticated) {
              navigate('/account/info')
            } else {
              dispatch(setAppData({ modalSignIn: true }))
            }
            toast.error('Falha na verificação de email')
          })
      }
    }
  }, [dispatch, isAuthenticated, navigate, pathname, search, urlPath, verifyEmail])

  return (
    <main className="">
      <Carousel />
      <Games />
      
      <section className="px-2">
        <section className="flex flex-col px-1 lg:px-28 py-2 items-center justify-between overflow-y-auto">
          <AnimatePresence mode={'wait'} initial={false}>
            <LayoutAnimation contentVisible={true}>
              <GreenFlow />
            </LayoutAnimation>
          </AnimatePresence>
        </section>
      </section>
      <TableLiveBetting />
    </main>
  )
}

export default HomePage
