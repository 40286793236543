import { SignupPayload } from '@/components/organisms/ModalSignup'
import { TCreditCardDeposit, TDepositWait, TPixDeposit, TWithdrawlWait } from '@/interfaces/application/depositType'
import { IPartnerResult } from '@/interfaces/application/partner'
import { IToBasicSubscriptionPresenter } from '@/interfaces/application/subscription'
import { User } from '@/interfaces/application/user'
import { TWithdraw } from '@/interfaces/application/widthdrawType'
import { preserveAppData, resetAppData, setAppData } from '@/store/app/reducer'
import { resetAuthData, setAuthData } from '@/store/auth/actions'
import { RootState } from '@/store/store'
import { resetUserData, setUserData } from '@/store/user/actions'
import { UserState } from '@/store/user/state'
import { resetWalletData, setWalletData } from '@/store/wallet/actions'
import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { encrypt } from '../lib/crypto'
import { toast } from 'react-toastify'


export type APIResponse<T> = {
  data?: T
  error: boolean
  errorObj?: any
}

const useApiService = () => {
  const token = useSelector((state: RootState) => state.auth.accessToken)
  const [accessToken, setAccessToken] = useState<string>(token)
  const dispatch = useDispatch()
  const apiUrl = import.meta.env.VITE_BACKEND_URL
  // 'http://localhost:3005'
  const axiosInstance: AxiosInstance = axios.create({
    baseURL: apiUrl || 'https://api.bcash.bet',
  })

  axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig<any>) => {
    if (accessToken && accessToken !== '') {
      config.headers!.Authorization = `Bearer ${accessToken}`
    }
    return config
  })

  useEffect(() => {
    setAccessToken(token)
  }, [token])

  const setLoadingToWait = (value: boolean) => {
    dispatch(setAppData({ loading: value }))
  }
  const signIn = async (username: string, password: string): Promise<APIResponse<undefined>> => {
    setLoadingToWait(true)
    try {
      const { data: loginResponse } = await axiosInstance.post('/auth/login', {
        login: username,
        password: encrypt(password),
      })

      const { data: validationUser } = (await axiosInstance.get('/auth/whoami', {
        headers: { Authorization: `Bearer ${loginResponse.accessToken}` },
      })) as AxiosResponse<User>

      dispatch(
        setAuthData({
          authenticated: true,
          accessToken: loginResponse.accessToken,
          expirationTime: loginResponse.exp,
        })
      )

      dispatch(setUserData(validationUser as unknown as UserState))

      dispatch(
        setWalletData({
          balance: validationUser.wallet.balance,
          virtualBalance: validationUser.wallet.virtualBalance,
          depositBalance: validationUser.wallet.depositBalance,
          decimalBalance: validationUser.wallet.decimalBalance,
          decimalVirtualBalance: validationUser.wallet.decimalVirtualBalance,
          decimalDepositBalance: validationUser.wallet.decimalDepositBalance,
          bonusWallets: validationUser.bonusWallets,
          withdrawPendingBalance: validationUser.withdrawPendingBalance,
        })
      )

      setLoadingToWait(false)
      return { error: false }
    } catch (err) {
      setLoadingToWait(false)
      console.error(err)

      dispatch(preserveAppData())
      dispatch(resetAuthData())
      dispatch(resetUserData())
      dispatch(resetWalletData())

      return { error: true, errorObj: err }
    }
  }

  const signOut = async (): Promise<void> => {
    setLoadingToWait(true)
    dispatch(resetAppData())
    dispatch(resetAuthData())
    dispatch(resetUserData())
    dispatch(resetWalletData())
    setLoadingToWait(false)
  }

  const registerUser = async (data: SignupPayload): Promise<APIResponse<any>> => {
    setLoadingToWait(true)
    try {
      await axiosInstance.post('/users', {
        dateBirth: data.dateBirth,
        login: data.login,
        email: data.email,
        password: data.password,
        invite: data.invite,
        cpf: data.cpf,
        day: data.day, // Adicionado
        month: data.month, // Adicionado
        year: data.year, // Adicionado
      })

      setLoadingToWait(false)
      return { error: false }
    } catch (err) {
      setLoadingToWait(false)
      return { error: true, errorObj: err }
    }
  }

  const updateUser = async (data: any): Promise<APIResponse<any>> => {
    setLoadingToWait(true)
    try {
      const body = _.cloneDeep(data)
      if (body.password) {
        body.password = encrypt(body.password)
      }
      await axiosInstance.put('/users', {
        ...body,
      })

      const { data: validationUser } = (await axiosInstance.get('/auth/whoami')) as AxiosResponse<User>

      dispatch(setUserData(validationUser as unknown as UserState))

      dispatch(
        setWalletData({
          balance: validationUser.wallet.balance,
          virtualBalance: validationUser.wallet.virtualBalance,
          depositBalance: validationUser.wallet.depositBalance,
          decimalBalance: validationUser.wallet.decimalBalance,
          decimalVirtualBalance: validationUser.wallet.decimalVirtualBalance,
          decimalDepositBalance: validationUser.wallet.decimalDepositBalance,
          fronzeBalance: validationUser.wallet.fronzeBalance,
          bonusWallets: validationUser.bonusWallets,
          withdrawPendingBalance: validationUser.withdrawPendingBalance,
        })
      )

      setLoadingToWait(false)
      return { error: false }
    } catch (err) {
      setLoadingToWait(false)

      const status = _.get(err, 'response.status')
      if (status === 401) {
        await signOut()
      }

      return { error: true, errorObj: err }
    }
  }

  const inactivateUser = async (id: string): Promise<APIResponse<any>> => {
    setLoadingToWait(true)
    try {
      await axiosInstance.delete(`/users/${id}`)
      setLoadingToWait(false)
      return { error: false }
    } catch (err) {
      setLoadingToWait(false)
      return { error: true, errorObj: err }
    }
  }

  const consultBrazilianCep = async (
    cep: string
  ): Promise<APIResponse<{ logradouro: string; uf: string; localidade: string }>> => {
    setLoadingToWait(true)
    try {
      const response = await axiosInstance.get(`https://viacep.com.br/ws/${cep}/json/`)
      setLoadingToWait(false)
      return {
        data: {
          localidade: response.data.localidade,
          logradouro: response.data.logradouro,
          uf: response.data.uf,
        },
        error: false,
      }
    } catch (err) {
      setLoadingToWait(false)
      return { error: true, errorObj: err }
    }
  }

  const depositCreditCard = async (data: TCreditCardDeposit): Promise<APIResponse<any>> => {
    setLoadingToWait(true)
    try {
      await axiosInstance.post('/deposits/creditCard', data)
      setLoadingToWait(false)
      return { error: false }
    } catch (err) {
      setLoadingToWait(false)
      return { error: true, errorObj: err }
    }
  }

  const depositPix = async (data: TPixDeposit): Promise<APIResponse<TDepositWait>> => {
    setLoadingToWait(true)
    try {
      const response = await axiosInstance.post<TDepositWait>('/deposits/pix', data)
      setLoadingToWait(false)
      return { error: false, data: response.data }
    } catch (err) {
      setLoadingToWait(false)
      return { error: true, errorObj: err }
    }
  }

  const depositWait = async (id: string): Promise<APIResponse<TDepositWait>> => {
    //setLoadingToWait(true)
    try {
      const response = await axiosInstance.get(`/deposits/${id}`)
      //setLoadingToWait(false)
      return { error: false, data: response.data }
    } catch (err) {
      //setLoadingToWait(false)
      return { error: true, errorObj: err }
    }
  }

  const updateUserDocument = async (type: string, file: File) => {
    try {
      setLoadingToWait(true)
      const formdata = new FormData()
      formdata.append('file', file)
      formdata.append('type', type)
      await axiosInstance.post('/users/documents', formdata)
      setLoadingToWait(false)
    } catch (err) {
      setLoadingToWait(false)
      return { error: true, errorObj: err }
    }
  }

  const withdrawPix = async (data: TWithdraw): Promise<APIResponse<TWithdrawlWait>> => {
    setLoadingToWait(true)
    try {
      const response = await axiosInstance.post<TWithdrawlWait>('/withdrawals/pix', data)
      await updateUserData()
      setLoadingToWait(false)
      return { error: false, data: response.data }
    } catch (err) {
      setLoadingToWait(false)
      return { error: true, errorObj: err }
    }
  }

  const getPartnerResult = async () => {
    setLoadingToWait(true)
    try {
      const response = await axiosInstance.get<IPartnerResult>('/partner/result')
      setLoadingToWait(false)
      return { error: false, data: response.data }
    } catch (err) {
      setLoadingToWait(false)
      return { error: true, errorObj: err }
    }
  }

  const recoveryPassword = async (email: string): Promise<APIResponse<undefined>> => {
    setLoadingToWait(true)
    try {
      await axiosInstance.post('/users/forgot-password', { email })
      setLoadingToWait(false)
      return { error: false }
    } catch (err) {
      setLoadingToWait(false)
      return { error: true, errorObj: err }
    }
  }

  const resetPassword = async (
    password: string,
    confirmPassword: string,
    token: string
  ): Promise<APIResponse<undefined>> => {
    setLoadingToWait(true)
    try {
      await axiosInstance.patch('/users/resetpassword', { password, confirmPassword, token })
      setLoadingToWait(false)
      return { error: false }
    } catch (err) {
      setLoadingToWait(false)
      return { error: true, errorObj: err }
    }
  }

  const updateUserData = async () => {
    setLoadingToWait(true)
    try {
      const { data: validationUser } = (await axiosInstance.get('/auth/whoami')) as AxiosResponse<User>

      dispatch(setUserData(validationUser as unknown as UserState))

      dispatch(
        setWalletData({
          balance: validationUser.wallet.balance,
          virtualBalance: validationUser.wallet.virtualBalance,
          depositBalance: validationUser.wallet.depositBalance,
          decimalBalance: validationUser.wallet.decimalBalance,
          decimalVirtualBalance: validationUser.wallet.decimalVirtualBalance,
          decimalDepositBalance: validationUser.wallet.decimalDepositBalance,
          fronzeBalance: validationUser.wallet.fronzeBalance,
          bonusWallets: validationUser.bonusWallets,
          withdrawPendingBalance: validationUser.withdrawPendingBalance,
        })
      )

      setLoadingToWait(false)
      return { error: false }
    } catch (err) {
      setLoadingToWait(false)

      const status = _.get(err, 'response.status')
      if (status === 401) {
        await signOut()
      }

      return { error: true, errorObj: err }
    }
  }

  const verifyCaptcha = async (token: string | null) => {
    setLoadingToWait(true)
    try {
      const { data: resultRecatpcha } = (await axiosInstance.post('/auth/recatcha', { token })) as AxiosResponse<
        'failed' | 'success'
      >

      if (resultRecatpcha === 'success') {
        setLoadingToWait(false)
        return { error: false }
      } else {
        setLoadingToWait(false)
        return { error: true }
      }
    } catch (err) {
      setLoadingToWait(false)
      return { error: true, errorObj: err }
    }
  }

  const subscriptionsPlan = async (planId: number) => {
    setLoadingToWait(true)
    try {
      const { data, status } = (await axiosInstance.post('subscriptions', {
        planId,
      })) as AxiosResponse<IToBasicSubscriptionPresenter>

      setLoadingToWait(false)

      if (status === 200) {
        return { error: false }
      } else {
        return { error: true, message: data.message || 'An error occurred' }
      }
    } catch (err) {
      setLoadingToWait(false)

      // Check if the error is an AxiosError to get response data
      if (axios.isAxiosError(err) && err.response) {
        return { error: true, message: err.response.data.message || 'An error occurred' }
      }

      return { error: true, message: 'An error occurred', errorObj: err }
    }
  }
  const verifyEmail = async (token: string) => {
    setLoadingToWait(true)
    try {
      await axiosInstance.patch('users/verify', {
        token,
      })

      setLoadingToWait(false)
      return { error: false }
    } catch (err) {
      setLoadingToWait(false)
      return { error: true, errorObj: err }
    }
  }

  const resendVerifyEmail = async () => {
    setLoadingToWait(true)
    try {
      await axiosInstance.patch('users/resend')
      setLoadingToWait(false)
      return { error: false }
    } catch (err) {
      setLoadingToWait(false)
      return { error: true, errorObj: err }
    }
  }

  const redeemCode = async (code: string): Promise<APIResponse<string>> => {
    setLoadingToWait(true)
    try {
      const response = await axiosInstance.post<{ message: string }>('/users/resgatar-codigo', {
        code,
      })
      setLoadingToWait(false)
      return { error: false, data: response.data.message }
    } catch (err) {
      setLoadingToWait(false)
      let errorMessage = 'Erro ao resgatar o código.'

      if (axios.isAxiosError(err)) {
        errorMessage = err.response?.data?.message || errorMessage
      }

      return { error: true, errorObj: errorMessage }
    }
  }

  const cancelWithdrawPending = async () => {
    setLoadingToWait(true)
    const response = await axiosInstance.post('/users/cancelar-saque', {
      headers: { Authorization: `Bearer ${token}` },
    })
    await updateUserData()
    setLoadingToWait(false)
    toast.success(response.data.message, { autoClose: 3000 })

    return { error: false, data: response.data.message }
  }

  return {
    signIn,
    signOut,
    registerUser,
    updateUser,
    verifyEmail,
    resendVerifyEmail,
    inactivateUser,
    updateUserDocument,
    depositCreditCard,
    depositPix,
    consultBrazilianCep,
    depositWait,
    withdrawPix,
    getPartnerResult,
    recoveryPassword,
    resetPassword,
    updateUserData,
    verifyCaptcha,
    subscriptionsPlan,
    redeemCode,
    cancelWithdrawPending,
  }
}

export default useApiService
