import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import homeIcone from '@/assets/icones/home.png'
// import jogarGratisButtonIcone from '@/assets/icones/button-jogar-gratuitamente.png'
import jogarButtonIcone from '@/assets/icones/button-jogar.png'
import fireIcone from '@/assets/icones/fire.png'
import searchButtonIcone from '@/assets/icones/search.png'
import newsIcone from '@/assets/icones/news.png'
import bcashIcone from '@/assets/icones/bcash.png'
import { useGameContext } from '@/context/GameContext'

const SearchPage: React.FC = (): JSX.Element => {
  const { gameCards } = useGameContext()
  const [activeCategory, setActiveCategory] = useState<string>('todos')
  const [searchTerm, setSearchTerm] = useState<string>('')

  const replaceSpacesWithHyphensInURL = (url: string): string => url.replace(/ /g, '-').toLowerCase()

  const filterData = () => {
    return gameCards.filter((game) => {
      const matchesCategory = activeCategory === 'todos' || game.category.includes(activeCategory)
      const matchesSearch = game.title.toLowerCase().includes(searchTerm.toLowerCase())
      return matchesCategory && matchesSearch
    })
  }

  const filteredData = filterData()

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setActiveCategory('todos')
    setSearchTerm(e.target.value)
  }

  return (
    <div
      className="w-[95%] relative space-y-4 flex flex-col lg:px-4 lg:mt-8 justify-center items-start mt-6 p-4"
      id="container-games"
    >
      <div className="flex flex-wrap items-center justify-between gap-2 h-10 text-base w-[98%] rounded-full border bg-[#0d2043] border-blue-300/30 md:mt-10">
        <div className="flex items-center gap-2 flex-grow justify-end">
          <div className="relative w-full ">
            <input
              className="w-full p-2 pl-12 pr-12 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 text-white bg-[#000918]"
              placeholder="Encontrar um Jogo"
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-1 cursor-pointer">
              <img src={searchButtonIcone} alt="Search Icon" className="h-8 w-8" />
            </div>
          </div>
        </div>
      </div>
      <div id="opcoes-filtro" className="w-full overflow-x-auto pb-2 max-w-[23rem] lg:max-w-none">
        <div className="flex items-center gap-0 md:gap-4 lg:gap-6 text-base flex-nowrap lg:justify-between">
          <button
            className={`flex items-center justify-center gap-2 p-[8px] md:p-[12px] border border-blue-300/30 min-w-[170px] md:min-w-[275px] font-medium text-sm md:text-md ${
              activeCategory === 'exclusivos' ? 'bg-[#e9a000] text-white' : 'bg-[#0843C1] text-white'
            } rounded-l-full md:rounded-full`}
          >
            <NavLink to={`/home`} className="flex items-center">
              <img src={bcashIcone} className="w-4 h-4" alt="Exclusivos" /> Exclusivos
            </NavLink>
          </button>
          <button
            className={`flex items-center justify-center gap-2 p-[8px] md:p-[12px] border-t border-b border-blue-300/30 min-w-[170px] md:min-w-[275px] font-medium text-sm md:text-md ${
              activeCategory === 'todos' ? 'bg-[#e9a000] text-white' : 'bg-[#0843C1] text-white'
            } md:rounded-full`}
            onClick={() => setActiveCategory('todos')}
          >
            <img src={homeIcone} className="w-4 h-4" alt="Home" /> Todos os jogos
          </button>
          <button
            className={`flex items-center justify-center gap-2 p-[8px] md:p-[12px] border-t border-b border-blue-300/30 min-w-[170px] md:min-w-[275px] font-medium text-sm md:text-md ${
              activeCategory === 'populares' ? 'bg-[#e9a000] text-white' : 'bg-[#0843C1] text-white'
            } md:rounded-full`}
            onClick={() => setActiveCategory('populares')}
          >
            <img src={fireIcone} className="w-4 h-4" alt="Populares" /> Populares
          </button>
          <button
            className={`flex items-center justify-center gap-2 p-[8px] md:p-[12px] border border-blue-300/30 min-w-[170px] md:min-w-[275px] font-medium text-sm md:text-md ${
              activeCategory === 'novidades' ? 'bg-[#e9a000] text-white' : 'bg-[#0843C1] text-white'
            } rounded-r-full md:rounded-full`}
            onClick={() => setActiveCategory('novidades')}
          >
            <img src={newsIcone} className="w-4 h-4" alt="Novidades" /> Novidades
          </button>
        </div>
      </div>

      <div className="grid grid-cols-3 md:grid-cols-5 gap-4 w-full">
        {filteredData.map((image, index) => (
          <div key={index} className="relative group">
            <img
              className="w-full border border-sky-500/50 transition-all block rounded-2xl"
              src={image.url}
              alt="Image art of game"
            />
            {image.title !== 'Em breve' && (
              <div className="absolute inset-0 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                <NavLink to={`/gaming/${replaceSpacesWithHyphensInURL(image.title)}`} className="mb-2">
                  <img src={jogarButtonIcone} alt="Jogar" className="w-20" />
                </NavLink>
                {/* <NavLink to={`/gaming/${replaceSpacesWithHyphensInURL(image.title)}`} className="mb-2">
                  <img src={jogarGratisButtonIcone} alt="Jogar Gratuitamente" className="w-20" />
                </NavLink> */}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default SearchPage
