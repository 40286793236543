import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface Props {
  children?: ReactNode
}

type DataLinksTypes = {
  id: number
  title: string
  path: string
}

const AccountPage: React.FC<Props> = ({ children }): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()
  const path = location.pathname

  const memoComponents = useMemo(
    () => [
      {
        id: 1,
        title: 'Informações da conta',
        path: '/account/info',
        visible: true,
      },
      {
        id: 2,
        title: 'Informações pessoais',
        path: '/account/personal',
        visible: true,
      },
      {
        id: 3,
        title: 'Comprovações',
        path: '/account/proofs',
        visible: true,
      },
      {
        id: 8,
        title: 'Suporte 24 horas',
        path: '/account/support',
        visible: true,
      },
      {
        id: 4,
        title: 'Depositar',
        path: '/account/deposit',
        visible: true,
      },
      {
        id: 5,
        title: 'Carteira',
        path: '/account/wallet',
        visible: true,
      },
      {
        id: 6,
        title: 'Inativar conta',
        path: '/account/disable',
        visible: true,
      },
      {
        id: 9,
        title: 'Bonus',
        path: '/account/partner',
        visible: true,
      },
    ],
    []
  )
  const [linkSelected, setLinkSelected] = useState<number>(1)

  useEffect(() => {
    const selectElement = memoComponents.find((item: DataLinksTypes) => item.path === path)
    setLinkSelected(selectElement ? selectElement.id : 1)
  }, [memoComponents, path])

  const handleClickLinkSelected = (data: DataLinksTypes): void => {
    setLinkSelected(data.id)
    navigate(data.path)
  }

  return (
    <main className="mt-5 ">
      <section className="defaultWidth flex flex-col gap-10 lg:gap-10 px-3   pb-[6rem]  overflow-y-auto lg:flex-row">
        <div className="hidden lg:flex flex-col gap-2 h-fit w-full lg:w-[300px] bg-[#0d2043] py-4 rounded-xl border-[1px]  border-blue-300/30">
          {memoComponents
            .filter((link) => link.visible)
            .map((link) => (
              <div
                key={link.id}
                onClick={() => handleClickLinkSelected(link)}
                className={`flex items-center gap-2 cursor-pointer hover:opacity-90 transition-all ${
                  linkSelected === link.id ? 'bg-gradient-to-b from-[#1442c8] to-[#14b1f2]' : ''
                }`}
              >
                <div className={`h-full w-full py-1 px-5 ml-1 ${linkSelected === link.id ? 'bg-[#01102c]' : ''}`}>
                  <span className="text-sm">{link.title}</span>
                </div>
              </div>
            ))}
        </div>
        <div className="w-full">{children}</div>
      </section>
    </main>
  )
}

export default AccountPage
