import Modal from '@/components/atoms/Modal'
import { ButtonType } from '@/interfaces/application/buttonType'
import { setAppData } from '@/store/app/actions'
import { RootState } from '@/store/store'
import React, { Fragment, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import tornsePremiumBanner from '@/assets/banner/torne-se-premium.webp'
import ganheEmDobroBanner from '@/assets/banner/ganhe-em-dobro.webp'
import FormButton from '@/components/atoms/FormButton'
import useApiService from '@/service/api.hook'
import { toast } from 'react-toastify'

const ModalBanner: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()

  const modalData = useSelector((state: RootState) => state.app.modalBanner)
  const { subscriptionsPlan } = useApiService()

  const closeModalBanner = useCallback(() => {
    dispatch(setAppData({ modalBanner: { status: false, type: '' } }))
  }, [dispatch])

  const subscriptionsPlanClick = async () => {
    const planId = modalData.type === 'premium' ? 1 : 2
    const response = await subscriptionsPlan(planId)

    if (response.error) {
      toast.error(response.message)
    } else {
      toast.success('Compra Efetivada!')
    }
  }

  return (
    <Modal isOpen={modalData.status} size="large" closeModal={() => closeModalBanner()} border="gold" nopadding={true}>
      <div className="relative">
        {modalData.type === 'premium' && (
          <Fragment>
            <img src={tornsePremiumBanner} alt="" className="w-[inherit] top-0 left-0 mt-[-10px]" />
            <div className="px-1 pb-4 pt-4">
              <div className="gap-1 mb-5 px-3 text-center">
                <div className="text-md font-bold">LLISTA DE PRÉ-LANÇAMENTO: PLANO PREMIUM</div>
               
                <div className="text-md font-bold text-pallet9 mb-4">TORNE-SE UM APOSTADOR PREMIUM NA BCASH.BET E RECEBA 50% DE SUAS APOSTAS CONVERTIDAS EM BÔNUS!</div>
                <p className="mb-4">
                  Experimente uma abordagem inovadora onde o impacto de apostas "erradas" é minimizado. Nossa plataforma
                  identifica suas apostas perdidas e devolve 50% do valor para sua carteira. Simples assim. Queremos que
                  você continue jogando e aumente suas chances de ganhar sem receios.
                </p>
                <p className="mb-4">
                  Aproveite a oferta exclusiva de pré-lançamento do Plano Premium por apenas R$200,00, em vez do valor
                  original de R$990,00. Esta oferta, com 78% de desconto, estará disponível apenas no dia 10/09/2024 e pode
                  ser retirada a qualquer momento. O Plano Premium terá duração de 7 dias a partir da sua ativação.
                </p>                

                <p className="mb-4">
                  <FormButton
                    title="QUERO ENTRAR NA LISTA"
                    onClick={() => subscriptionsPlanClick()}
                    className={
                      'h-[38px] rounded-full text-center bg-gradient-to-r from-pallet5 to-pallet6 border border-pallet9 text-bold'
                    }
                    buttonType={ButtonType.Deposit}
                  ></FormButton>
                </p>
                <p className="mb-4">
                  <FormButton
                    title="Voltar"
                    onClick={() => closeModalBanner()}
                    buttonType={ButtonType.Deposit}
                  ></FormButton>
                </p>

                <p>
                  <small>
                    *O valor será automaticamente debitado do seu saldo da plataforma. Ao aceitar essa oferta você concorda
                    com os temos e politicas do plano "Plano Premium".
                  </small>
                </p>
              </div>
            </div>
          </Fragment>
        )}
        {modalData.type === 'dobro' && (
          <Fragment>
            <img src={ganheEmDobroBanner} alt="" className="w-[inherit] top-0 left-0 mt-[-10px]" />
            <div className="px-1 pb-4 pt-4">
              <div className="gap-1 mb-5 px-3 text-center">
                <div className="text-md font-bold text-pallet9 mb-4">
                  LISTA DE PRÉ-LANÇAMENTO: LEIA ATÉ O FINAL E APROVEITE!
                </div>
                <p className="mb-4">
                  Este bônus é destinado a jogadores iniciantes que desejam otimizar suas apostas. Aproveite a
                  funcionalidade 'Double Win', desenvolvida diretamente pela plataforma para aumentar a sua chance de
                  ganhar. Ao adquirir o plano 'Ganhos em Dobro', você terá a chance de multiplicar seus ganhos por dois em
                  cada aposta vitoriosa.
                </p>
                <p className="mb-4">
                  Para participar, entre na lista exclusiva de lançamento. Esta oferta especial estará disponível somente no
                  dia 10/09/2024 e você pode obter o plano por R$200,00, em vez do valor original de R$1.299,00. O plano
                  ficará disponível por 7 dias após a compra. Ressaltamos que a oferta pode ser retirada a qualquer momento.
                  Não perca essa oportunidade de maximizar seus ganhos.
                </p>

                <p className="mb-4">
                  <FormButton
                    title="QUERO ENTRAR NA LISTA"
                    onClick={() => subscriptionsPlanClick()}
                    className={
                      'h-[38px] rounded-full text-center bg-gradient-to-r from-pallet5 to-pallet6 border border-pallet9 text-bold'
                    }
                    buttonType={ButtonType.Deposit}
                  ></FormButton>
                </p>
                <p className="mb-4">
                  <FormButton
                    title="Voltar"
                    onClick={() => closeModalBanner()}
                    buttonType={ButtonType.Deposit}
                  ></FormButton>
                </p>

                <p>
                  <small>
                    *O valor será automaticamente debitado do seu saldo da plataforma. Ao aceitar essa oferta você concorda
                    com os temos e politicas do plano "Ganhos em Dobro".
                  </small>
                </p>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Modal>
  )
}

export default ModalBanner
