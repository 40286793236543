import React from 'react'

const ServiceTermPage: React.FC = (): JSX.Element => {
  return (
    <section className="bg-pallet1  w-full p-4 rounded-xl border-pallet2 border">
      <div className="p-8 w-full mx-auto  rounded shadow">
        <h1 className="text-3xl font-bold mb-6">1. TERMOS DE SERVIÇO</h1>

        <h2 className="text-2xl font-bold mb-4">AVISO IMPORTANTE</h2>

        <p className="mb-4">
          1.1 AO SE REGISTRAR NO SITE da Bcash.bet , VOCÊ ENTRA EM UM CONTRATO COM A BCASH.BET. E CONCORDA EM FICAR
          VINCULADO (I) A ESTES TERMOS E CONDIÇÕES; (II) À NOSSA POLÍTICA DE PRIVACIDADE; (III) À NOSSA POLÍTICA DE COOKIES
          E (IV) AS REGRAS APLICÁVEIS AOS NOSSOS PRODUTOS DE APOSTAS OU JOGOS, CONFORME REFERENCIADO POSTERIORMENTE NESTES
          TERMOS E CONDIÇÕES ("TERMOS E CONDIÇÕES" OU "ACORDO"), E AS REGRAS ESPECÍFICAS DE APOSTAS E/OU JOGOS, E SÃO
          CONSIDERADOS COMO TENDO ACEITADO E ENTENDIDO TODOS OS TERMOS.
        </p>

        <p className="mb-4">
          1.2 LEIA ESTE ACORDO CUIDADOSAMENTE PARA GARANTIR QUE VOCÊ COMPREENDA TOTALMENTE O SEU CONTEÚDO. SE VOCÊ TIVER
          DÚVIDAS SOBRE SEUS DIREITOS E OBRIGAÇÕES DECORRENTES DA ACEITAÇÃO DESTE ACORDO, CONSULTE UM ADVOGADO EM SUA
          JURISDIÇÃO ANTES DE CONTINUAR A USAR O(S) SITE(S) E ACESSAR O SEU CONTEÚDO. SE VOCÊ NÃO ACEITAR OS TERMOS, NÃO
          USE, VISITE OU ACESSE NENHUMA PARTE DO SITE.
        </p>

        <h2 className="text-3xl font-bold mb-6">2. GERAL</h2>

        <p className="mb-4">2.1 Ao se registrar em Bcash.bet, você entra em um acordo com a BCASH.BET.</p>

        <p className="mb-4">
          2.2 Este Acordo deve ser lido por você na íntegra antes de usar o serviço ou produtos da Bcash.bet. Observe que
          o Acordo constitui um acordo legalmente vinculativo entre você e a BCASH.BET.
        </p>

        <p className="mb-4">
          2.3 Estes Termos e Condições entram em vigor assim que você concluir o processo de registro, que inclui marcar a
          caixa de aceitação destes Termos e Condições e criar com sucesso uma conta. Ao usar qualquer parte do site após a
          criação da conta, você concorda que esses Termos e Condições se apliquem ao uso do site.
        </p>

        <p className="mb-4">
          2.4 Temos o direito de fazer alterações nestes Termos e Condições a qualquer momento e sem aviso prévio. Se
          fizermos tais alterações, podemos tomar medidas apropriadas para chamar sua atenção para essas mudanças (como por
          e-mail ou colocando um aviso em uma posição proeminente no site, juntamente com os termos e condições alterados),
          mas será de sua exclusiva responsabilidade verificar quaisquer alterações, atualizações e/ou modificações. Seu uso
          contínuo dos serviços do site após qualquer alteração a estes Termos e Condições será considerado como sua
          aceitação e acordo em ficar vinculado por tais alterações, atualizações e/ou modificações.
        </p>

        <p className="mb-4">
          2.5 Os termos destes Termos e Condições prevalecerão no caso de qualquer conflito entre os termos destes Termos e
          Condições e de qualquer das regras do jogo ou outros documentos referenciados nestes Termos e Condições.
        </p>

        <p className="mb-4">
          2.6 Estes Termos e Condições podem ser publicados em vários idiomas para fins informativos e facilidade de acesso
          pelos jogadores. A versão em português (BR) é a única base legal da relação entre você e nós e, no caso de
          qualquer discrepância em relação a uma tradução de qualquer tipo, a versão em português (BR) destes Termos e
          Condições prevalecerá.
        </p>

        <h2 className="text-3xl font-bold mb-6">3. CONTA DE PARTICIPAÇÃO</h2>

        <h3 className="text-xl font-bold mb-4">Cadastro</h3>

        <p className="mb-4">
          3.1 Para poder efetuar apostas na Bcash.bet, é necessário inicialmente efetuar o registro pessoal de uma conta
          conosco.
        </p>

        <p className="mb-4">
          3.2 Para que uma pessoa seja inscrita como jogador Bcash.bet e utilize o site, é necessário que ela envie uma
          solicitação de registro e abertura de uma conta na Bcash.bet. A solicitação de abertura da Conta de Participação
          deverá ser apresentada pessoalmente e exigirá que o usuário forneça informações pessoais, e-mail, nome completo,
          data de nascimento, etc.
        </p>

        <p className="mb-4">
          3.3 Caso as informações especificadas em 3.2 não sejam fornecidas e/ou não sejam consideradas completas, precisas
          ou atualizadas a qualquer momento, a Bcash.bet reserva-se o direito de suspender o registro da Conta Bcash.bet
          e considerar quaisquer depósitos subsequentes feitos na Conta Bcash.bet do jogador como inválidos (e quaisquer
          ganhos provenientes desses depósitos serão anulados). Quando uma conta da Bcash.bet é suspensa, o usuário deve
          entrar em contato com o suporte ao cliente em Suporte@Bcash.bet.
        </p>

        <p className="mb-4">
          3.4 Todos os candidatos devem ter 18 anos de idade ou superior, de acordo com as leis aplicáveis a você,
          prevalecendo a maior idade entre as duas. A Bcash.bet reserva-se o direito de solicitar comprovação da idade de
          qualquer jogador e suspender sua Conta Bcash.bet até que documentação seja fornecida. A Bcash.bet leva a sério
          suas responsabilidades em relação a menores de idade e ao jogo responsável.
        </p>

        <p className="mb-4">
          3.5 A Bcash.bet não aceitará inscrições de indivíduos que se enquadrem nas seguintes categorias:
        </p>
        <p className="ml-8 mb-4">a) Menores de 18 anos ou menores de idade legal para jogos em sua jurisdição;</p>
        <p className="ml-8 mb-4">
          b) Residentes em jurisdições onde o jogo seja ilegal ou não permitido. A Bcash.bet não pode verificar a
          legalidade do serviço em todas as jurisdições, sendo responsabilidade do usuário garantir a legalidade do uso do
          serviço;
        </p>
        <p className="ml-8 mb-4">c) Fornecedores de informações enganosas ou tentativas de representação de terceiros.</p>

        <p className="mb-4">
          3.6 A Bcash.bet reserva-se o direito de recusar qualquer solicitação de abertura de uma Conta Bcash.bet a seu
          exclusivo critério.
        </p>

        <p className="mb-4">
          3.7 Você declara e garante que todas as informações fornecidas em seu formulário de inscrição são verdadeiras,
          atualizadas e corretas.
        </p>

        <p className="mb-4">
          3.8 A Bcash.bet reserva-se o direito, a qualquer momento, de solicitar qualquer documentação KYC que considere
          necessária para determinar a identidade e localização de um jogador. A Bcash.bet reserva-se o direito de
          restringir o serviço, pagamentos ou saques até que a identidade seja suficientemente verificada, ou por qualquer
          outro motivo, a critério exclusivo da Bcash.bet. A Bcash.bet também reserva-se o direito de divulgar
          informações de um jogador conforme apropriado para cumprir processos legais ou conforme permitido pela política de
          privacidade da Bcash.bet (proprietário e operador da Bcash.bet), e ao utilizar o serviço, o usuário reconhece
          e concorda com a possibilidade de tal divulgação.
        </p>

        <p className="mb-4">
          3.9 Somente é permitida uma Conta de Apostas por jogador. Se você tentar ou conseguir com sucesso abrir mais de
          uma Conta BCASH.BET, em seu próprio nome ou sob qualquer outro nome, ou se tentar ou conseguir utilizar o site
          através da Bcash.bet de outra pessoa, a Bcash.bet terá o direito de encerrar imediatamente todas as suas
          Contas Bcash.bet, reter todos os fundos nelas depositados e proibir seu uso futuro do site.
        </p>

        <p className="mb-4">
          3.10 Caso a Bcash.bet tenha motivos para acreditar que você registrou e/ou usou mais de uma Conta Bcash.bet,
          ou conspirou com um ou mais indivíduos para utilizar uma série de Contas diferentes, a Bcash.bet terá o direito
          de considerar essas contas como múltiplas Contas e suspender ou encerrar todas essas contas. A Bcash.bet também
          terá o direito de reter os fundos até que o jogador prove que não tentou criar múltiplas contas.
        </p>

        <p className="mb-4">
          3.11 Caso você perceba que possui mais de uma Conta Bcash.bet registrada, deve notificar-nos imediatamente. Não
          fazer isso pode levar ao bloqueio de sua conta e a retenção dos fundos.
        </p>

        <p className="mb-4">
          3.12 É de sua exclusiva responsabilidade garantir que seus dados de login sejam mantidos em segurança. Não deve
          divulgar seus dados de login a ninguém.
        </p>

        <p className="mb-4">
          3.13 Não somos responsáveis por qualquer abuso ou uso indevido de sua Conta Bcash.bet terceiros devido à
          divulgação de seus dados de login, seja intencional, acidental, ativa ou passivamente.
        </p>

        <p className="mb-4">
          3.14 Você não tem permissão para vender, transferir ou adquirir Contas de Aposta de ou para outros jogadores.
        </p>

        <p className="mb-4">
          3.15 Deve nos informar imediatamente caso identifique quaisquer erros em relação à sua conta ou a cálculos
          relacionados a qualquer aposta que tenha feito. Reservamo-nos o direito de anular e cancelar quaisquer apostas
          sujeitas a tais erros.
        </p>

        <p className="mb-4">3.16 Você é responsável por todas as atividades que ocorrem em sua conta.</p>

        <p className="mb-4">
          3.17 A Bcash.bet reserva-se o direito de encerrar ou suspender sua Conta quando considerar apropriado.
        </p>

        <h2 className="text-3xl font-bold mb-6">4. Suas Garantias</h2>

        <p className="mb-4">
          4.1 Antes de utilizar o Serviço e de forma contínua, você afirma, assegura, concorda e aceita que:
        </p>

        <p className="mb-4">
          4.2 Você possui mais de 18 anos ou a maioridade legal, de acordo com as leis que se aplicam a você, prevalecendo a
          idade que for maior;
        </p>

        <p className="mb-4">
          4.3 Você está plenamente capacitado para celebrar um contrato legal conosco e não está sujeito a nenhuma forma de
          incapacidade legal;
        </p>

        <p className="mb-4">
          4.4 Você não foi diagnosticado ou classificado como um jogador compulsivo ou com problemas de jogo;
        </p>

        <p className="mb-4">
          4.5 Você não está atualmente autoexcluído de nenhum site ou local de jogos de azar. Você deve informar
          imediatamente a Bcash.bet se celebrar um acordo de autoexclusão com qualquer provedor de jogos de azar.
        </p>

        <h3 className="text-xl font-bold mb-4">Jurisdição</h3>

        <p className="mb-4">4.6 Você está acessando a Bcash.bet de uma jurisdição onde é legal fazê-lo;</p>

        <p className="mb-4">
          4.7 Você não utilizará nossos serviços enquanto estiver localizado em uma jurisdição que proíba apostas online
          e/ou jogos de cassino e/ou jogos ao vivo;
        </p>

        <p className="mb-4">
          4.8 Você reconhece e aceita que reservamos o direito de identificar e impedir o uso de técnicas proibidas,
          incluindo, entre outras, a detecção de transações fraudulentas, registro e inscrição automáticos, técnicas de jogo
          e capturas de tela. Essas medidas podem incluir, mas não se limitam a, a análise dos dispositivos dos jogadores, a
          detecção de localização geográfica e o mascaramento de IP.
        </p>

        <h3 className="text-xl font-bold mb-4">Fundos e Impostos</h3>

        <p className="mb-4">
          4.9 Você é o único responsável por relatar e pagar quaisquer impostos aplicáveis relacionados aos ganhos que você
          obtiver conosco, de acordo com as leis pertinentes;
        </p>

        <p className="mb-4">
          4.10 Você não depositará fundos provenientes de atividades criminosas ou outras atividades não autorizadas;
        </p>

        <p className="mb-4">4.11 Você não utilizará métodos de pagamento que não estejam em seu nome;</p>

        <p className="mb-4">
          4.12 Todos os fundos depositados serão utilizados exclusivamente para os serviços disponíveis no site;
        </p>

        <p className="mb-4">4.13 Você não sacará ou tentará sacar para métodos de pagamento que não lhe pertençam;</p>

        <p className="mb-4">
          4.14 Você compreende que ao participar dos serviços disponíveis no site, há o risco de perder o dinheiro
          depositado;
        </p>

        <p className="mb-4">4.15 A participação não será tratada como uma instituição financeira.</p>

        <h3 className="text-xl font-bold mb-4">Outros</h3>

        <p className="mb-4">4.16 O uso do Serviço é uma escolha exclusiva, baseada em seu critério e risco pessoal;</p>

        <p className="mb-4">4.17 Você não se envolverá em atividades criminosas por meio de sua conta da Bcash.bet;</p>

        <p className="mb-4">
          4.18 Todas as informações fornecidas durante a vigência deste acordo são verdadeiras, completas e corretas, e você
          deverá nos notificar imediatamente sobre qualquer alteração nessas informações;
        </p>

        <p className="mb-4">
          4.19 Você participa dos jogos estritamente em caráter pessoal e não profissional, exclusivamente para fins de
          lazer e entretenimento;
        </p>

        <p className="mb-4">4.20 Você joga em seu próprio nome e não em nome de terceiros;</p>

        <p className="mb-4">4.21 Você possui apenas uma conta conosco e concorda em não abrir contas adicionais;</p>

        <p className="mb-4">
          4.22 A Bcash.bet não tem controle sobre as redes de telecomunicações e os serviços de acesso à Internet
          necessários para acessar e utilizar o Serviço, e não assume responsabilidade por interrupções, lentidões,
          restrições de capacidade ou outras deficiências que possam afetá-los;
        </p>

        <p className="mb-4">
          4.23 Você não se envolverá em atividades fraudulentas, conspiratórias, de manipulação ou outras atividades ilegais
          relacionadas à sua participação nos jogos e/ou serviços do site, e não utilizará métodos assistidos por software
          ou técnicas ou dispositivos de hardware para sua participação;
        </p>

        <p className="mb-4">
          4.24 Em caso de violação de qualquer uma das declarações, garantias ou acordos mencionados acima em 4.1 a
          Bcash.bet reserva-se o direito de encerrar ou suspender sua conta na Bcash.bet a seu critério e anular
          quaisquer apostas na medida permitida por lei.
        </p>

        <h2 className="text-3xl font-bold mb-6">5. DEPÓSITOS</h2>

        <p className="mb-4">
          5.1 Para participar de qualquer Jogo, é necessário possuir fundos suficientes na sua Conta de Aposta. Para tanto,
          você deve utilizar os métodos de pagamento disponíveis no Site para depositar seus recursos. A Bcash.bet não
          concede crédito pela participação em qualquer Jogo.
        </p>

        <p className="mb-4">
          5.2 É preciso depositar fundos na sua conta de aposta por meio dos métodos de pagamento disponíveis em
          (www.Bcash.bet).
        </p>

        <p className="mb-4">
          5.3 Certifique-se de que os fundos que você deposita na sua Conta de Participação não têm origem em atividades
          ilegais ou são de fontes ilegais.
        </p>

        <p className="mb-4">
          5.4 Para efetuar depósitos na sua Conta da Bcash.bet, você pode transferir recursos através do PIX, entre outros
          métodos de pagamento disponíveis em Bcash.bet. Lembre-se de que os depósitos devem ser feitos exclusivamente com
          recursos próprios.
        </p>

        <p className="mb-4">
          5.5 O depósito de dinheiro na sua conta deve ser destinado exclusivamente para fazer apostas no Site. A
          Bcash.bet tem o direito de suspender ou encerrar sua conta se houver suspeitas de que você está depositando
          recursos sem a intenção de fazer apostas. Em tais situações, a Bcash.bet pode relatar a atividade às autoridades
          relevantes.
        </p>

        <p className="mb-4">
          5.6 Você reconhece que apenas métodos de pagamento de sua propriedade podem ser usados para financiar sua Conta de
          Participação.
        </p>

        <p className="mb-4">5.7 Esteja ciente de que se a Bcash.bet descobrir que você:</p>

        <p className="ml-8 mb-4">
          a) Financiou/está financiando sua Conta de Participação com métodos de pagamento de terceiros; e/ou
        </p>

        <p className="ml-8 mb-4">
          b) Financiou/está financiando sua Conta de Participação com recursos ilegais, isso será considerado uma violação
          dos Termos de Serviço, equivalente a fraude, e, portanto:
        </p>

        <p className="ml-16 mb-4">
          5.7.1 A Bcash.bet se reserva o direito de suspender ou encerrar sua Conta, a seu critério.
        </p>

        <p className="ml-16 mb-4">
          5.7.2 A Bcash.bet se reserva o direito de cancelar, reverter ou ajustar quaisquer transações, resultando na
          perda dos recursos depositados e/ou dos ganhos gerados a partir dos recursos depositados.
        </p>

        <p className="mb-4">
          5.8 A Bcash.bet pode estabelecer um valor mínimo de depósito a seu critério, o qual pode ser alterado a qualquer
          momento, conforme identificado no site. Lembre-se de que dependendo do método de pagamento escolhido, podem haver
          taxas adicionais cobradas pelos provedores de pagamento.
        </p>

        <p className="mb-4">
          5.9 Os métodos de pagamento disponíveis e os limites de depósito mínimo e máximo podem ser encontrados na seção de
          carteira do Site. As taxas de serviço aplicáveis estão sujeitas a alterações, e alguns métodos de pagamento podem
          não estar disponíveis em todos os países.
        </p>

        <p className="mb-4">
          5.10 Os depósitos são processados imediatamente, e o saldo é atualizado instantaneamente na Conta de Participação
          sempre que um provedor de serviços de pagamento é usado. A Bcash.bet não é responsável por atrasos decorrentes
          do sistema de pagamento ou causados por terceiros. Observe que:
        </p>

        <p className="ml-8 mb-4">
          a) Alguns métodos de pagamento podem envolver taxas adicionais, claramente visíveis no caixa.
        </p>

        <p className="ml-8 mb-4">
          b) Seu banco ou provedor de serviços de pagamento pode cobrar taxas adicionais por conversão de moeda, de acordo
          com seus termos e condições.
        </p>

        <p className="mb-4">
          5.11 Reservamo-nos o direito de empregar procedimentos e meios adicionais para verificar sua identidade ao
          processar depósitos na sua Conta de Aposta.
        </p>

        <p className="mb-4">
          5.12 Os fundos não podem ser transferidos da sua Conta de Aposta para a Conta de aposta de outro Jogador.
        </p>

        <p className="mb-4">
          5.13 A Bcash.bet pode recusar depósitos a seu critério. Usuários com contas bloqueadas ou suspensas devem
          abster-se de depositar em www.Bcash.bet.
        </p>

        <p className="mb-4">
          5.14 Caso um jogador tente depositar quando sua conta estiver bloqueada ou suspensa, a Bcash.bet tem o direito
          de reter os recursos.
        </p>

        <h2 className="text-3xl font-bold mb-6">6. RETIRADAS</h2>

        <p className="mb-4">
          6.1 A Bcash.bet mantém o direito de recusar qualquer solicitação de saque de um jogador da Bcash.bet até que:
        </p>

        <p className="ml-8 mb-4">
          a) A identidade do jogador tenha sido verificada e a Bcash.bet tenha confirmado que o saque está sendo
          solicitado pelo titular da conta de apostas;
        </p>

        <p className="ml-8 mb-4">b) O saque esteja sendo transferido para uma conta da qual o jogador é o titular legal;</p>

        <p className="ml-8 mb-4">c) Qualquer informação adicional solicitada pela Bcash.bet tenha sido fornecida; e</p>

        <p className="ml-8 mb-4">d) O jogador tenha cumprido o requisito de aposta mínima para cada depósito.</p>

        <p className="mb-4">
          6.2 A Bcash.bet reserva todos os direitos para investigar a conta e atividade de jogo do jogador. Se a
          Bcash.bet razoavelmente suspeitar que a conta ou atividade de jogo do jogador violou estes termos de serviço ou
          as leis ou regulamentos aplicáveis, ela poderá, a seu critério exclusivo, atrasar ou recusar novos depósitos,
          saques e/ou jogos enquanto conduz sua investigação. O jogador reconhece e aceita que a Bcash.bet pode não estar
          em posição de fornecer uma explicação sobre a natureza de sua investigação.
        </p>

        <p className="mb-4">
          6.3 Todos os saques devem ser processados através do mesmo método de pagamento escolhido pelo jogador ao fazer um
          depósito, a menos que a Bcash.bet decida de outra forma ou não seja possível fazê-lo. Se o jogador depositar
          usando vários métodos de pagamento, a Bcash.bet reserva o direito de dividir o saque entre esses métodos de
          pagamento e processar cada parte de acordo com suas políticas e regulamentos de combate à lavagem de dinheiro.
        </p>

        <p className="mb-4">
          6.4 Se por engano creditarmos ganhos na conta de apostas do jogador que não lhe pertençam, seja devido a um erro
          técnico nas tabelas de pagamento, erro humano ou outra razão, o valor permanecerá de propriedade da Bcash.bet e
          será deduzido da conta de apostas do jogador. Se o jogador retirar fundos que não lhe pertençam antes de termos
          conhecimento do erro, o valor pago por engano constituirá uma dívida do jogador conosco, sem prejuízo de outras
          soluções legais disponíveis. Em caso de crédito incorreto, o jogador é obrigado a nos notificar imediatamente por
          e-mail ou via chat ao vivo.
        </p>

        <p className="mb-4">6.5 Para solicitar um saque em moeda FIAT, o jogador deve apostar 100% do valor do depósito.</p>

        <p className="mb-4">
          6.6 Os saques da conta de apostas podem ser direcionados ao jogador ou transferidos para uma conta bancária em
          nome do jogador, conforme informado à Bcash.bet. Antes de processar qualquer saque, a Bcash.bet se reserva o
          direito de realizar a devida diligência quando necessário.
        </p>

        <p className="mb-4">6.7 O valor mínimo de saque será especificado no site no momento do pedido de saque.</p>

        <p className="mb-4">
          6.8 Se o jogador tiver vários saques pendentes, a Bcash.bet se reserva o direito de rejeitar todos os saques e
          solicitar que o jogador realize um saque único, combinando o valor de todos os saques múltiplos.
        </p>

        <p className="mb-4">
          6.9 A Bcash.bet não imporá nenhuma taxa de saque ao jogador. No entanto, é aconselhável verificar se o método de
          pagamento utilizado impõe encargos adicionais. A Bcash.bet não será responsável por quaisquer comissões ou taxas
          de processamento cobradas pelo jogador por terceiros, como o banco do jogador, pois isso está além de seu
          controle.
        </p>

        <p className="mb-4">
          6.10 A Bcash.bet reserva-se o direito de realizar procedimentos adicionais de verificação KYC para qualquer
          saque. Os jogadores que desejam recuperar fundos de uma conta fechada, bloqueada ou excluída são aconselhados a
          entrar em contato com Suporte@Bcash.bet.
        </p>

        <p className="mb-4">
          6.11 Todas as transações serão verificadas para prevenir a lavagem de dinheiro. Se um jogador detectar qualquer
          atividade suspeita relacionada a qualquer um dos jogos do site, ele/ela deverá relatar imediatamente à
          Bcash.bet. A Bcash.bet pode suspender, bloquear ou encerrar uma conta e reter fundos, se solicitado a fazê-lo
          de acordo com a Lei de Prevenção à Lavagem de Dinheiro ou qualquer outra base legal solicitada por autoridades
          estaduais. O jogador reconhece que os fundos da conta são consumidos instantaneamente durante o jogo e não há
          devolução de mercadorias, reembolsos ou cancelamentos retroativos da conta.
        </p>

        <h2 className="text-3xl font-bold mb-6">7. Erros dentro do nosso site</h2>

        <p className="mb-4">
          A Bcash.bet não se responsabilizará se você tentar ou se beneficiar de quaisquer equívocos relacionados a
          apostas no site, desde que esteja agindo de má-fé. Isso inclui situações em que:
        </p>

        <p className="ml-8 mb-4">
          7.1 haja erros óbvios nas probabilidades, spreads, handicaps, totais e pagamentos relevantes;
        </p>

        <p className="ml-8 mb-4">
          7.2 uma aposta seja calculada ou paga de forma incorreta, incluindo o saque total de uma aposta.
        </p>

        <p className="ml-8 mb-4">
          7.3 ocorra qualquer erro em um gerador de números aleatórios ou nas tabelas de pagamento usadas em qualquer jogo
          ou produto.
        </p>
      </div>
    </section>
  )
}

export default ServiceTermPage
