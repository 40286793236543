import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserState } from './state'

const initialState: UserState = {
  id: '',
  address: '',
  cep: '',
  city: '',
  country: '',
  dateBirth: '',
  email: '',
  frontDocument: '',
  inviteCode: '',
  login: '',
  name: '',
  phone: '',
  photo: '',
  rearDocument: '',
  ruleId: 1,
  state: '',
  emailVerifiedAt: undefined,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state: UserState, action: PayloadAction<Partial<UserState>>) => {
      Object.assign(state, action.payload)
    },
    resetUserData: (state: UserState) => {
      Object.assign(state, initialState)
    },
  },
})

export const { setUserData, resetUserData } = userSlice.actions
export const userReducer = userSlice.reducer
