import FormButton from '@/components/atoms/FormButton'
import FormInput from '@/components/atoms/FormInput'
import { ButtonType } from '@/interfaces/application/buttonType'
import useApiService from '@/service/api.hook'
import { RootState } from '@/store/store'
import { UserState } from '@/store/user/state'
import { yupResolver } from '@hookform/resolvers/yup'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

type FormData = {
  name: string
  dateBirth: string
  cep: string
  address: string
  country: string
  state: string
  city: string
}

const PersonalInfoPage: React.FC = (): JSX.Element => {
  const { consultBrazilianCep, updateUser } = useApiService()
  const schema = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    dateBirth: Yup.string().required('Campo obrigatório'),
    cep: Yup.string().required('Campo obrigatório'),
    address: Yup.string().required('Campo obrigatório'),
    country: Yup.string().required('Campo obrigatório'),
    state: Yup.string().required('Campo obrigatório'),
    city: Yup.string().required('Campo obrigatório'),
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  })

  const userData: UserState = useSelector((state: RootState) => state.user)

  useEffect(() => {
    setValue('name', userData.name)
    setValue('dateBirth', format(parseISO(userData.dateBirth), 'dd/MM/yyyy'))
    setValue('cep', userData.cep)
    setValue('address', userData.address)
    setValue('country', userData.country)
    setValue('state', userData.state)
    setValue('city', userData.city)
  }, [userData, setValue])

  // Faz uma requisição para buscar os dados do cep
  const getZipCodeData = async (cep: string) => {
    if (cep.length == 9) {
      try {
        const response = await consultBrazilianCep(cep)

        if (response.error && response.data) {
          setValue('address', response.data.logradouro)
          setValue('state', response.data.uf)
          setValue('city', response.data.localidade)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  const handleFormSubmit: SubmitHandler<FormData> = async (data) => {
    updateUser(data)
      .then(() => {
        console.log('success')
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <section className="bg-pallet1  w-full p-4 rounded-xl border-pallet2 border">
      <div>
        <h1 className="pb-1 text-xl font-medium">Informações pessoais</h1>
      </div>
      <form className="mt-6 space-y-4 " onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="flex flex-col lg:flex-row items-center gap-4">
          <FormInput register={register} errors={errors} required name="name" label="Nome Completo" />
        </div>
        <div className="flex flex-col lg:flex-row items-center gap-4 lg:gap-12">
          <FormInput
            register={register}
            errors={errors}
            required
            formatType="date:dd/MM/yyyy"
            placeholder="00/00/0000"
            maxLength={10}
            name="dateBirth"
            label="Data de nascimento"
          />
          <FormInput
            register={register}
            errors={errors}
            required
            name="cep"
            label="CEP"
            formatType="cep"
            maxLength={9}
            onChange={(e) => getZipCodeData(e.target.value)}
          />
        </div>
        <div className="flex flex-col lg:flex-row items-center gap-4 lg:gap-12">
          <FormInput register={register} errors={errors} required name="address" label="Endereço" />
          <FormInput register={register} errors={errors} required name="country" label="Pais" />
        </div>
        <div className="flex flex-col lg:flex-row items-center gap-4 lg:gap-12">
          <FormInput register={register} errors={errors} required name="state" label="Estado" />
          <FormInput register={register} errors={errors} required name="city" label="Cidade" />
        </div>
        <div className="pt-4 flex justify-center lg:justify-start">
          <FormButton
            title="Salvar"
            type="submit"
            className="w-[90%] lg:w-[200px] uppercase font-medium rounded-full"
            buttonType={ButtonType.Confirm}
          />
        </div>
      </form>
    </section>
  )
}

export default PersonalInfoPage
