import React from 'react'

import greenMobile from '@/assets/green-flow/mobile.webp'
import backgroundFlowGreen from '@/assets/green-flow/wide.webp'
import useDevice from '@/service/device.hook'

const GreenFlow: React.FC = (): JSX.Element => {
  const { isMobile } = useDevice()

  return (
    <section className=" lg:pt-3  ">
      <div className="w-full relative">
        {/* Background Image */}
        <img
          className="rounded-2xl   inset-0 hidden lg:block lg:w-full"
          src={backgroundFlowGreen}
          alt="Image degrade default"
        />
        {/* Mobile Image */}
        <img className="rounded-2xl  border border-blue-950/70 inset-0 lg:hidden" src={greenMobile} alt="banner" />

        {/* Time Schedule */}
        <div className="absolute lg:top-[68%] lg:left-[67%] lg:translate-y-[-67%] lg:translate-x-[-68%] lg:w-auto  left-[76.5%] top-[65%] translate-x-[-75%] translate-y-[-67%]">
          <div className="font-bold flex flex-col lg:flex-row items-center justify-center gap-4">
            <div className=" lg:gap-12 gap-2 font-normal h-[35px]">
              <table
                className={isMobile ? 'text-[10px] mt-[2px] w-[170px] hidden md:block ' : 'w-[300px] mt-[-10px] ml-[10px]'}
              >
                <tbody className="">
                  <tr>
                    <td align="right">06:00 - </td>
                    <td>06:30</td>
                    <td align="right">18:00 - </td>
                    <td>18:30</td>
                  </tr>
                  <tr>
                    <td align="right">12:00 - </td>
                    <td>12:30</td>
                    <td align="right">00:00 - </td>
                    <td>00:30</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default GreenFlow
