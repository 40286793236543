function loadLocalFont(fontPath: string, fontName: string): void {
  const fontFace = new FontFace(fontName, `url(${fontPath})`)
  document.fonts.add(fontFace)
  fontFace.load().then(() => {
    document.documentElement.style.fontFamily = `${fontName}, sans-serif`
  })
}

// Caminho para a fonte local
const localFontPath = '/fonts/sora.woff2'

// Carregando a fonte local
loadLocalFont(localFontPath, 'Sora')

// Mantenha a estrutura de exportação original
const fonts = { sora: 'Sora, sans-serif' }

export default fonts
