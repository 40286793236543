import React, { useState } from 'react'
import IconEmail from '@/components/atoms/Icons/Email'

const SupportPage: React.FC = (): JSX.Element => {
  const supportEmail = 'suporte@Bcash.bet'

  const accordionItems = [
    {
      title: 'Eu estou com problema no saque',
      content: `Desde já lamentamos por essa horrível experiência, ao tentar realizar o saque. Verifique se você já fez o seu primeiro saque diário, caso não peço que esteja aguardando um período de 24 horas, caso o problema não seja solucionado no decorrer, esteja nos direcionando um e-mail com provas do ocorrido nosso endereço específico para essas soluções: saque@Bcash.bet. Estará recebendo uma resposta/solução, o mais breve possível.`,
    },
    {
      title: 'Quero me comunicar com o suporte 24h',
      content:
        'Para qualquer questão que precise de suporte a qualquer hora do dia, por favor, envie um e-mail para suporte@Bcash.bet. Nossa equipe de suporte estará à disposição para ajudar e responder às suas dúvidas 24 horas por dia, 7 dias por semana.',
    },
    {
      title: 'Encontrei um problema ao tentar depositar',
      content:
        ' Se você estiver enfrentando dificuldades ao tentar fazer um depósito, lembramos que o valor pode demorar até 24h para ser depositado em sua conta na Bcash.bet. Caso o deposito não for finalizado ou tiver outro problema, envie um e-mail detalhado para deposito@Bcash.bet. Nossa equipe trabalhará para resolver o problema rapidamente.',
    },
    {
      title: 'Encontrei um bug no site e quero reportar',
      content:
        'Agradecemos por relatar isso. Para reportar um bug no site, por favor, envie um e-mail detalhado para suporte@Bcash.bet. Nossa equipe técnica analisará a questão e trabalhará para corrigi-la o mais rápido possível',
    },
    {
      title: 'Não estou conseguindo apostar',
      content:
        'Se você estiver enfrentando dificuldades para realizar apostas, entre em contato conosco enviando um e-mail para suporte@Bcash.bet. Nossa equipe estará pronta para ajudar a solucionar o problema.',
    },
    {
      title: 'Estou com dificuldades para comprar o premium',
      content:
        'Se você estiver enfrentando dificuldades para comprar o Premium, envie um e-mail detalhado para suporte.premium@Bcash.bet. Nossa equipe especializada em questões Premium ficará feliz em ajudar.',
    },
    // Adicione mais itens conforme necessário
  ]

  const [accordionStates, setAccordionStates] = useState(accordionItems.map(() => false))

  const toggleAccordion = (index: number) => {
    const newAccordionStates = [...accordionStates]
    newAccordionStates[index] = !newAccordionStates[index]
    setAccordionStates(newAccordionStates)
  }

  return (
    <div>
      <section className="bg-[#000B24] w-full p-4 rounded-2xl border-pallet2 border">
        <div>
          <div className="flex items-center gap-4">
            <h1 className="pb-1 text-xl font-medium text-white">Suporte e Perguntas Frequentes</h1>
          </div>
          <div className="text-xs mt-3 text-white text-center py-5">Toque em uma das perguntas abaixo</div>
          <div id="fac" className="mt-4 flex flex-wrap p-2 space-bee">
            {accordionItems.map((item, index) => (
              <div key={index} className="w-full md:w-1/2 p-2">
                <div
                  className="cursor-pointer bg-gradient-to-r bg-[#023AC5] text-white rounded-md"
                  style={{ padding: '15px' }}
                  onClick={() => toggleAccordion(index)}
                >
                  <div className="flex justify-between">
                    <h2 className="text-md font-medium">{item.title} </h2>
                    <svg
                      fill={'#fff'}
                      width={'25px'}
                      data-name="Camada 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 64 64"
                    >
                      <polygon points="49.48 16.64 32 34.12 14.52 16.64 7.9 23.26 32 47.36 56.1 23.26 49.48 16.64" />
                    </svg>
                  </div>
                  {accordionStates[index] && (
                    <div className="p-4">
                      <p>{item.content}</p>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Seção de Contatos para suporte */}
      <section className="mt-8 bg-[#000B24] w-full p-4 rounded-2xl border-pallet2 border">
        <div className="flex items-center gap-4 justify-center">
          <h1 className="pb-1 text-xl font-medium text-white">Contatos para suporte</h1>
          <IconEmail className="w-6 h-6" />
        </div>
        <div className="text-xs text-gray-400 mt-3 text-white text-center">
          Para falar diretamente com o nosso suporte, mande um e-mail para{' '}
          <a className="text-white underline cursor-pointer" href={`mailto:${supportEmail}`} target="_blank">
            {supportEmail}
          </a>
        </div>
      </section>
    </div>
  )
}

export default SupportPage
