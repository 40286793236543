import React, { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import IconVerified from '@/components/atoms/Icons/Verified'

// Importando os assets
import homeIcone from '@/assets/icones/home.png'
// import jogarGratisButtonIcone from '@/assets/icones/button-jogar-gratuitamente.png'
import jogarButtonIcone from '@/assets/icones/button-jogar.png'
import fireIcone from '@/assets/icones/fire.png'
import searchButtonIcone from '@/assets/icones/search.png'
import newsIcone from '@/assets/icones/news.png'
import bcashIcone from '@/assets/icones/bcash.png'
import bcashDourada from '@/assets/icones/logo-bcash-dourada.png'
import emBreveCapa from '@/assets/card-games/EmBreve.webp'

import { CardGames } from '@/interfaces/application/cardGames'
import useDevice from '@/service/device.hook'
import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules' // Certifique-se de importar o módulo de navegação
import closeIcon from '@/assets/icones/close-desktop.png'
import './styles.css'
import ModalSearch from '@/components/organisms/CustomHeader/ModalSearch'
import top6 from '@/assets/top10-banner/6.png'
import top7 from '@/assets/top10-banner/7.png'
import top8 from '@/assets/top10-banner/8.png'
import top9 from '@/assets/top10-banner/9.png'
import top10 from '@/assets/top10-banner/10.png'

type Props = {
  titleOne: string
  titleTwo: string
  data: CardGames[]
  newData: CardGames[]
}

const ContainerGames: React.FC<Props> = ({ titleOne, titleTwo, data, newData }): JSX.Element => {
  const [getMaxSlides, setMaxSlides] = useState<number>(7)
  const [cardActive, setCardActive] = useState<number | null>(null)
  const [newCardActive, setNewCardActive] = useState<number | null>(null)
  const [activeCategory, setActiveCategory] = useState<string>('exclusivos')
  const [additionalItems, setAdditionalItems] = useState<CardGames[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalPosition, setModalPosition] = useState<{ top: number; left: number; width: number } | undefined>(undefined)

  const { isMobile } = useDevice()
  const swiperRef = useRef({} as any)
  const swiperRefNew = useRef({} as any) // Novo swiper ref
  const maxSlideDesktop = 5
  const maxSlideMobile = 3
  const replaceSpacesWithHyphensInURL = (url: string): string => url.replace(/ /g, '-').toLowerCase()

  useEffect(() => {
    const maxSlides = isMobile ? maxSlideMobile : maxSlideDesktop
    setMaxSlides(maxSlides)
  }, [isMobile, maxSlideDesktop, maxSlideMobile])

  useEffect(() => {
    if (isModalOpen) {
      const searchDiv = document.querySelector('.flex.flex-wrap.items-center.justify-between.gap-2') as HTMLElement
      if (searchDiv) {
        const rect = searchDiv.getBoundingClientRect()
        setModalPosition({ top: rect.bottom + window.scrollY, left: rect.left + window.scrollX, width: rect.width })
      }
    } else {
      setModalPosition(undefined)
    }
  }, [isModalOpen])

  const handleClickOutside = (event: MouseEvent) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target as Node) &&
      !document.querySelector('.modal-class')?.contains(event.target as Node)
    ) {
      setIsModalOpen(false)
    }
  }

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isModalOpen])

  const getSlidesPerView = () => {
    return isMobile ? 3.5 : getMaxSlides
  }

  const filterData = () => {
    if (activeCategory === 'todos') {
      return data
    }
    return data.filter((game) => game.category.includes(activeCategory))
  }

  const filteredData2 = data
    .filter((game) => game.title.toLowerCase().includes(searchTerm.toLowerCase()))
    .map((game) => ({
      url: game.url,
      title: game.title,
      game_url: game.gameUrlBcash || '',
    }))

  useEffect(() => {
    const filteredData = filterData()
    const additionalItemsCount = 8 - filteredData.length

    const newAdditionalItems = Array.from(
      { length: additionalItemsCount },
      (_, index) =>
        ({
          id: `coming-soon-${index}`,
          title: 'Em breve',
          subtitle: '',
          url: emBreveCapa,
          function: '',
          favorite: 0,
          provider: '',
          uuid: '',
          gameType: '',
          category: [],
          backgroundUrl: '',
          gameUrlBcash: null,
        }) as CardGames
    )

    setAdditionalItems(newAdditionalItems)
  }, [activeCategory, data])

  const filteredData = filterData()

  return (
    <div
      className="w-full relative space-y-2 flex flex-col lg:px-4 lg:mt-8 justify-center items-start mt-6"
      id="container-games"
    >
      <div className="flex flex-wrap items-center justify-between gap-2 h-[45px] text-base w-[98%] rounded-full border-[1px] bg-[#0d2043] border-blue-300/30 md:mt-10">
        <div className="flex gap-2 pl-1 items-center justify-center flex-shrink-0">
          <span className="w-7 h-7">
            <IconVerified />
          </span>
          <span className="flex">{titleOne}</span>
        </div>

        <div className="flex items-center gap-2 flex-grow justify-end">
          <NavLink to={`/search`}>
            <label className="text-show-all hidden md:block cursor-pointer">Mostrar Todos</label>
          </NavLink>
          <div className="relative hidden sm:block">
            <input
              ref={inputRef}
              className="p-2 pl-12 pr-12 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 text-white bg-[#000918]"
              placeholder="Encontrar um Jogo"
              type="text"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value)
                setIsModalOpen(e.target.value !== '') // Abre o modal apenas se o campo não estiver vazio
              }}
            />
            <div className="absolute inset-y-0 left-0 flex items-center pl-1 cursor-pointer">
              <img src={searchButtonIcone} alt="Search Icon" className="h-8 w-8" />
            </div>
            {searchTerm && (
              <div
                className="absolute inset-y-0 right-0 flex items-center pr-1 cursor-pointer"
                onClick={() => {
                  setSearchTerm('')
                  setIsModalOpen(false) // Fechar o modal
                }}
              >
                <img src={closeIcon} alt="Close Icon" className="h-8 w-8" />
              </div>
            )}
          </div>
          <div className="flex h-[40px]">
            <div className="cursor-pointer review-swiper-button-prev rounded-tl-[24px] text-sm rounded-bl-[24px] flex-1 flex items-center bg-gradient-to-r from-pallet8 to-pallet7 h-full w-[46px] justify-center border-black">
              <svg
                fill="#fff"
                height={20}
                id="Camada_1"
                data-name="Camada 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 64 64"
              >
                <path
                  className="cls-1"
                  d="m7.15,32L27.48,11.67l6.78,6.78-9.04,9.04h31.62v9.04h-31.62s9.04,9.04,9.04,9.04l-6.78,6.78L7.15,32Z"
                />
              </svg>
            </div>
            <div className="cursor-pointer review-swiper-button-next rounded-tr-[24px] text-sm rounded-br-[24px] flex-1 flex items-center bg-gradient-to-r from-pallet7 to-pallet8 h-full w-[46px] justify-center">
              <svg
                fill="#fff"
                height={20}
                className="text-white"
                data-name="Camada 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 64 64"
              >
                <path
                  className="cls-1 text-white"
                  d="m56.85,32l-20.33-20.33-6.78,6.78,9.04,9.04H7.15v9.04h31.62l-9.04,9.04,6.78,6.78,20.33-20.33Z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div
        id="opcoes-filtro"
        className="flex items-center justify-between gap-0 md:gap-4 lg:gap-6 text-base overflow-x-auto max-w-[92vw] md:max-w-[800px] lg:max-w-full scroll-container pb-2 w-full"
      >
        <button
          className={`button-category ${
            activeCategory === 'exclusivos' ? 'selected' : ''
          } rounded-l-full rounded-r-none md:rounded-r-full md:flex-1 md:w-auto`}
          onClick={() => setActiveCategory('exclusivos')}
        >
          <img src={bcashIcone} alt="Exclusivos" /> Exclusivos
        </button>

        <button
          className={`button-category ${
            activeCategory === 'todos' ? 'selected' : ''
          } rounded-none md:rounded-full md:flex-1 md:w-auto`}
        >
          <NavLink to={`/search`} className="flex items-center">
            <img src={homeIcone} alt="Home" className="mr-2" /> Todos os jogos
          </NavLink>
        </button>

        <button
          className={`button-category ${
            activeCategory === 'populares' ? 'selected' : ''
          } rounded-none md:rounded-full md:flex-1 md:w-auto`}
          onClick={() => setActiveCategory('populares')}
        >
          <img src={fireIcone} alt="Populares" /> Populares
        </button>

        <button
          className={`button-category ${
            activeCategory === 'novidades' ? 'selected' : ''
          } rounded-none rounded-r-full rounded-l-none md:rounded-l-full md:flex-1 md:w-auto`}
          onClick={() => setActiveCategory('novidades')}
        >
          <img src={newsIcone} alt="Novidades" /> Novidades
        </button>
      </div>

      <div className="container-carrossel">
        <Swiper
          className="swiper-custom"
          modules={[Navigation]}
          spaceBetween={10}
          centeredSlides={false}
          slidesPerView={getSlidesPerView()}
          pagination={false}
          ref={swiperRef}
          onReachBeginning={() => {}}
          onReachEnd={() => {}}
          loop={true}
          autoplay={isMobile ? { delay: 90000 } : {}}
          navigation={{
            nextEl: '.review-swiper-button-next',
            prevEl: '.review-swiper-button-prev',
          }}
        >
          {[...filteredData, ...additionalItems].map((image, index) => (
            <SwiperSlide
              key={index}
              onMouseLeave={() => setCardActive(null)}
              onMouseOver={() => setCardActive(typeof image.id === 'number' ? image.id : null)}
              onTouchStart={() => setCardActive(typeof image.id === 'number' ? image.id : null)}
              className="swiper-slide-custom swipper-jogos"
              style={{ zIndex: cardActive === image.id ? 10 : 1 }}
            >
              <img
                className={`swipper-jogos-img w-full border border-sky-500/50 transition-all cover  ${
                  cardActive && image.id === cardActive && 'opacity-20'
                } block rounded-2xl`}
                src={image.url}
                alt="Image art of game"
              />
              {typeof image.id === 'number' && image.id === cardActive && image.title !== 'Em breve' && (
                <div className="absolute inset-0 flex flex-col items-center justify-center z-20">
                  <NavLink
                    to={`/gaming/${replaceSpacesWithHyphensInURL(image.title)}`}
                    className="flex items-center justify-center mb-4 nav-link-limited"
                  >
                    <img src={jogarButtonIcone} alt="Jogar" className="button-game" />
                  </NavLink>
                  {/* <NavLink
                    to={`/gaming/${replaceSpacesWithHyphensInURL(image.title)}`}
                    className="flex items-center justify-center nav-link-limited mt-1"
                  >
                    <img src={jogarGratisButtonIcone} alt="Jogar Gratuitamente" className="button-game" />
                  </NavLink> */}
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="flex flex-wrap items-center justify-between gap-2 h-[40px] text-base w-[98%] rounded-full border-[1px] bg-[#0d2043] border-blue-300/30 my-custom-margin2 ">
        <div className="flex gap-2 pl-1 items-center justify-center flex-shrink-0">
          <span className="w-7 h-7">
            <img src={bcashDourada} alt="Exclusivos" />
          </span>
          <span className="flex">{titleTwo}</span>
        </div>

        <div className="flex h-[40px]">
          <div className="cursor-pointer new-review-swiper-button-prev rounded-tl-[24px] text-sm rounded-bl-[24px] flex-1 flex items-center bg-gradient-to-r from-pallet9 to-pallet5 h-full w-[46px] justify-center ">
            <svg
              fill="#fff"
              height={20}
              id="Camada_1"
              data-name="Camada 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 64 64"
            >
              <path
                className="cls-1"
                d="m7.15,32L27.48,11.67l6.78,6.78-9.04,9.04h31.62v9.04h-31.62s9.04,9.04,9.04,9.04l-6.78,6.78L7.15,32Z"
              />
            </svg>
          </div>
          <div className="cursor-pointer new-review-swiper-button-next rounded-tr-[24px] text-sm rounded-br-[24px] flex-1 flex items-center bg-gradient-to-r from-pallet5 to-pallet9 h-full w-[46px] justify-center">
            {' '}
            <svg
              fill="#fff"
              height={20}
              className="text-white"
              data-name="Camada 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 64 64"
            >
              <path
                className="cls-1 text-white"
                d="m56.85,32l-20.33-20.33-6.78,6.78,9.04,9.04H7.15v9.04h31.62l-9.04,9.04,6.78,6.78,20.33-20.33Z"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className="w-full my-custom-margin">
        <Swiper
          modules={[Navigation]} // Incluir o módulo de navegação
          spaceBetween={10}
          centeredSlides={false}
          slidesPerView={getMaxSlides}
          pagination={false}
          ref={swiperRefNew}
          onReachBeginning={() => {}}
          onReachEnd={() => {}}
          loop={true}
          //   autoplay={isMobile ? { delay: 2000 } : {}}
          navigation={{
            nextEl: '.new-review-swiper-button-next',
            prevEl: '.new-review-swiper-button-prev',
          }}
        >
          {newData.map((image, index) => (
            <SwiperSlide
              key={index}
              onMouseLeave={() => setNewCardActive(null)}
              onMouseOver={() => setNewCardActive(typeof image.id === 'number' ? image.id : null)}
              onTouchStart={() => setNewCardActive(typeof image.id === 'number' ? image.id : null)}
              className="swiper-slide-custom swipper-top-10"
              style={{ zIndex: newCardActive === image.id ? 10 : 1 }}
            >
              <NavLink
                to={`/gaming/${replaceSpacesWithHyphensInURL(image.title)}`}
                className="flex items-center justify-center mt-0 mb-0 sm:mb-4 nav-link-limited"
              >
                <img
                  className={`swipper-top10-img  object-contain transition-all cover ${
                    newCardActive && image.id === newCardActive && 'opacity-20'
                  } block rounded-2xl`}
                  src={image.url}
                  alt="Image art of game"
                />
              </NavLink>
              {/* {image.id === newCardActive && image.title !== 'Em breve' && (
                <div className="absolute inset-0 left-0 right-0 bottom-0 top-0 w-full h-full flex flex-col items-center justify-center z-20">
                  <NavLink
                    to={`/gaming/${replaceSpacesWithHyphensInURL(image.title)}`}
                    className="flex items-center justify-center mb-4"
                  >
                    <img src={jogarButtonIcone} alt="Jogar" className="button-game" />
                  </NavLink>
                  <NavLink
                    to={`/gaming/${replaceSpacesWithHyphensInURL(image.title)}`}
                    className="flex items-center justify-center"
                  >
                    <img src={jogarGratisButtonIcone} alt="Jogar Gratuitamente" className="button-game" />
                  </NavLink>
                </div>
              )} */}
            </SwiperSlide>
          ))}

          <SwiperSlide
            key={6}
            className="swiper-slide-custom swipper-top-10"
            style={{ zIndex: newCardActive === 6 ? 10 : 1 }}
          >
            <img
              className={`swipper-top10-img  object-contain transition-all cover mt-0 sm:mt-0 ${
                newCardActive && 6 === newCardActive && 'opacity-20'
              } block rounded-2xl`}
              src={top6}
              alt="Image art of game"
            />
          </SwiperSlide>

          <SwiperSlide
            key={7}
            className="swiper-slide-custom swipper-top-10"
            style={{ zIndex: newCardActive === 7 ? 10 : 1 }}
          >
            <img
              className={`swipper-top10-img  object-contain transition-all cover mt-0 sm:mt-0 ${
                newCardActive && 7 === newCardActive && 'opacity-20'
              } block rounded-2xl`}
              src={top7}
              alt="Image art of game"
            />
          </SwiperSlide>

          <SwiperSlide
            key={8}
            className="swiper-slide-custom swipper-top-10"
            style={{ zIndex: newCardActive === 8 ? 10 : 1 }}
          >
            <img
              className={`swipper-top10-img  object-contain transition-all cover mt-0 sm:mt-0 ${
                newCardActive && 8 === newCardActive && 'opacity-20'
              } block rounded-2xl`}
              src={top8}
              alt="Image art of game"
            />
          </SwiperSlide>

          <SwiperSlide
            key={9}
            className="swiper-slide-custom swipper-top-10"
            style={{ zIndex: newCardActive === 9 ? 10 : 1 }}
          >
            <img
              className={`swipper-top10-img  object-contain transition-all cover mt-0 sm:mt-0 ${
                newCardActive && 9 === newCardActive && 'opacity-20'
              } block rounded-2xl`}
              src={top9}
              alt="Image art of game"
            />
          </SwiperSlide>

          <SwiperSlide
            key={10}
            className="swiper-slide-custom swipper-top-10"
            style={{ zIndex: newCardActive === 10 ? 10 : 1 }}
          >
            <img
              className={`swipper-top10-img  object-contain transition-all cover mt-0 sm:mt-0 ${
                newCardActive && 10 === newCardActive && 'opacity-20'
              } block rounded-2xl`}
              src={top10}
              alt="Image art of game"
            />
          </SwiperSlide>
        </Swiper>
      </div>
      <ModalSearch
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        filteredData={filteredData2}
        position={modalPosition} // Passar a posição apenas se calculada
      />
    </div>
  )
}

export default ContainerGames
