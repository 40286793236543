import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WalletState } from './state'

const initialState: WalletState = {
  balance: 0,
  virtualBalance: 0,
  depositBalance: 0,
  decimalBalance: 0,
  decimalVirtualBalance: 0,
  decimalDepositBalance: 0,
  bonusWallets: [],
  fronzeBalance: 0,
  decimalFronzeBalance: 0,
  withdrawPendingBalance: 0,
  updateDate: null,
}

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWalletData: (state: WalletState, action: PayloadAction<Partial<WalletState>>) => {
      state.balance = action.payload.balance ?? state.balance
      state.virtualBalance = action.payload.virtualBalance ?? state.virtualBalance
      state.depositBalance = action.payload.depositBalance ?? state.depositBalance
      state.decimalBalance = action.payload.decimalBalance ?? state.decimalBalance
      state.decimalVirtualBalance = action.payload.decimalVirtualBalance ?? state.decimalVirtualBalance
      state.decimalDepositBalance = action.payload.decimalDepositBalance ?? state.decimalDepositBalance
      state.bonusWallets = action.payload.bonusWallets ?? state.bonusWallets
      state.fronzeBalance = action.payload.fronzeBalance ?? state.fronzeBalance
      state.decimalFronzeBalance = action.payload.decimalFronzeBalance ?? state.decimalFronzeBalance
      state.withdrawPendingBalance = action.payload.withdrawPendingBalance ?? state.withdrawPendingBalance
      state.updateDate = new Date()
    },
    resetWalletData: (state: WalletState) => {
      state.balance = 0
      state.virtualBalance = 0
      state.depositBalance = 0
      state.decimalBalance = 0
      state.decimalVirtualBalance = 0
      state.decimalDepositBalance = 0
      state.bonusWallets = []
      state.fronzeBalance = 0
      state.decimalFronzeBalance = 0
      state.withdrawPendingBalance = 0
      state.updateDate = null
    },
  },
})

export const { setWalletData, resetWalletData } = walletSlice.actions
export const walletReducer = walletSlice.reducer
