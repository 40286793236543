import React from 'react'

const ResponsiblePage: React.FC = (): JSX.Element => {
  return (
    <section className="bg-pallet1  w-full p-4 rounded-xl border-pallet2 border">
      <div className="p-8 w-full mx-auto  rounded shadow">
        <div>
          <h1 className="text-2xl font-bold mb-6">JOGO RESPONSÁVEL</h1>

          <div className="mb-8">
            <h2 className="text-xl font-bold mb-4">Visão Geral</h2>
            <p>
              Para a maioria das pessoas, o jogo é uma atividade de lazer e entretenimento agradável. No entanto, para
              algumas, o jogo pode ter impactos negativos. Como resultado, desenvolvemos esta política para comunicar nossa
              abordagem ao jogo responsável e à minimização dos danos a consumidores que possam estar vulneráveis ao
              problema do jogo.
            </p>
            <p>
              Bcash.bet está comprometido em oferecer um ambiente seguro para todos os seus jogadores apostarem conosco,
              é por isso que é tão importante manter a segurança com o Bcash.bet! O jogo de cassino online deve ser uma
              forma de entretenimento que não cause danos a outras áreas de sua vida.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="text-xl font-bold mb-4">Abordagens para Jogo Responsável</h2>
            <ol className="list-decimal list-inside">
              <li>
                <p>
                  Fornecer informações aos clientes sobre seu jogo e gastos, para que possam tomar decisões informadas sobre
                  seu jogo.
                </p>
              </li>
              <li>
                <p>
                  Ter ferramentas de jogo mais seguro disponíveis (como limites, pausas e autoexclusões) que estão
                  disponíveis para todos os clientes para serem colocados em suas contas a qualquer momento.
                </p>
              </li>
              <li>
                <p>
                  Fornecer informações sobre suporte externo para ajudar na prevenção de danos relacionados ao jogo e ao
                  jogo problemático.
                </p>
              </li>
              <li>
                <p>
                  Uma Equipe de Atendimento ao Cliente disponível 24 horas por dia, 7 dias por semana, para ajudar com
                  dúvidas sobre o jogo mais seguro.
                </p>
              </li>
              <li>
                <p>Garantir que menores de idade não possam criar contas no Bcash.bet.</p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <h1 className="text-2xl font-bold mb-6">Política de Jogo Responsável</h1>

          <div className="mb-8">
            <h2 className="text-xl font-bold mb-4">Compromisso com a Responsabilidade</h2>
            <p>
              Comprometemo-nos a compartilhar a responsabilidade, juntamente com governos, comunidades e indivíduos, para
              ajudar os clientes a controlar seu hábito de jogo e auxiliar os jogadores problemáticos a identificar seus
              problemas e buscar ajuda.
            </p>
            <p>Algumas das maneiras como fazemos isso incluem:</p>
            <ol className="list-decimal list-inside">
              <li>
                <p>Permitir que nossos clientes se autoexcluam (temporariamente ou permanentemente) das apostas conosco;</p>
              </li>
              <li>
                <p>Fornecer informações e mensagens de Jogo Responsável aos nossos clientes;</p>
              </li>
              <li>
                <p>Treinar nossa equipe em Jogo Responsável durante a indução e pelo menos uma vez por ano;</p>
              </li>
              <li>
                <p>
                  Encaminhar nossos clientes afetados pelo problema do jogo para serviços de apoio relacionados ao jogo;
                </p>
              </li>
            </ol>
          </div>

          <div className="mb-8">
            <h2 className="text-xl font-bold mb-4">Objetivos</h2>
            <ol className="list-decimal list-inside">
              <li>
                <p>Minimizar a extensão dos danos relacionados ao jogo para indivíduos e para a comunidade em geral;</p>
              </li>
              <li>
                <p>Permitir que os clientes tomem decisões informadas sobre seus hábitos de jogo;</p>
              </li>
              <li>
                <p>Oferecer às pessoas afetadas pelo jogo acesso a assistência e informações oportunas e apropriadas;</p>
              </li>
              <li>
                <p>
                  Promover uma compreensão compartilhada entre indivíduos, comunidades, a indústria de jogos de azar e o
                  governo das práticas de jogo responsável;
                </p>
              </li>
              <li>
                <p>
                  Garantir que a indústria de jogos de azar forneça ambientes seguros e de apoio para a oferta de produtos e
                  serviços de jogo.
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <h1 className="text-2xl font-bold mb-6">O que é Jogo Seguro?</h1>
          <p className="mb-4">
            Compreender o jogo seguro é fundamental para garantir que você esteja aproveitando ao máximo a experiência no
            Bcash.bet.
          </p>
          <div className="mb-8">
            <h2 className="text-xl font-bold mb-4">Dicas para jogar com segurança incluem:</h2>
            <ol className="list-decimal list-inside">
              <li>
                <p>Seu jogo é equilibrado com outras atividades ou passatempos que você tem.</p>
              </li>
              <li>
                <p>
                  Antes de começar a jogar, você tem um plano em mente de quanto deseja gastar com o jogo e por quanto tempo
                  estará jogando.
                </p>
              </li>
              <li>
                <p>Apostar com dinheiro que você pode pagar.</p>
              </li>
              <li>
                <p>Fazer pausas ou parar de jogar durante o dia.</p>
              </li>
              <li>
                <p>Compreender as probabilidades e conhecer os riscos associados ao jogo.</p>
              </li>
              <li>
                <p>Não ficar excessivamente chateado e irritado com as perdas quando estiver jogando.</p>
              </li>
              <li>
                <p>Saiba mais dicas com o nosso guia de jogo responsável.</p>
              </li>
            </ol>
          </div>

          <div className="mb-8">
            <h2 className="text-xl font-bold mb-4">Compreender as Probabilidades</h2>
            <p>
              Há muitos mitos e equívocos sobre o jogo - entender as probabilidades do jogo ajuda a colocar as chances de
              ganhar em perspectiva.
            </p>
          </div>

          <p>O Bcash.bet oferece um serviço de cassino.</p>
        </div>
        <div>
          <h2 className="text-xl font-bold mb-4">Vantagem da Casa/Borda da Casa</h2>
          <p className="mb-4">
            Os jogos têm uma vantagem da casa para garantir que uma porcentagem do valor total apostado seja devolvida à
            Casa, que, neste caso, é o Bcash.bet.
          </p>

          <div className="mb-8">
            <h3 className="text-lg font-bold mb-2">Aleatoriedade</h3>
            <p className="mb-4">
              Todo jogo tem um elemento de aleatoriedade - não há como controlar o resultado de qualquer jogo de cassino ou
              evento esportivo.
            </p>
          </div>

          <div className="mb-8">
            <h3 className="text-lg font-bold mb-2">Resultados Independentes</h3>
            <p className="mb-4">
              Jogos/Rodadas não estão relacionados. Seus resultados não dependem de quanto tempo você jogou o jogo
              anteriormente.
            </p>
          </div>

          <div>
            <h3 className="text-lg font-bold mb-2">Probabilidades e Probabilidade</h3>
            <p className="mb-4">
              As probabilidades e a probabilidade são maneiras de descrever suas chances de ganhar. Embora as probabilidades
              possam parecer baixas, não há certezas nas apostas.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-xl font-bold mb-4">Formas de Podemos Ajudá-lo a Permanecer Bcash.bet Seguro!</h2>
          <p className="mb-4">
            Bcash.bet está comprometido em incentivar os jogadores a considerar sua própria atividade, fornecendo as
            ferramentas necessárias para garantir que estejam no controle e jogando com dinheiro que podem pagar.
          </p>

          <div className="mb-8">
            <h3 className="text-lg font-bold mb-2">Usando Ferramentas de Jogo Mais Seguro em Sua Conta</h3>
            <p className="mb-4">
              Bcash.bet oferece uma variedade de ferramentas que podem ser aplicadas à sua conta para definir limites
              seguros para você jogar.
            </p>

            <div className="mb-4">
              <h4 className="text-md font-bold mb-2">Limites de Jogo</h4>
              <p className="mb-2">
                <span className="font-bold">Limite de Perda:</span> Um limite de perda permite que você defina um limite
                para o quanto você pode perder durante um período definido. O limite pode ser definido ao longo de um dia,
                uma semana ou um mês. É um limite líquido de perda. Os lucros obtidos durante o período definido não
                contarão para o valor do limite. Se você for lucrativo durante o período definido, poderá usar seus lucros
                para continuar a apostar até atingir o limite de perda. Apostas não resolvidas contam para o limite.
                Verifique suas apostas não resolvidas se não puder fazer apostas, pois essas apostas não resolvidas podem
                estar fazendo você atingir seu limite.
              </p>
              <p className="mb-2">
                <span className="font-bold">Limite de Aposta:</span> Um limite de aposta permite que você defina um limite
                para o quanto pode apostar durante um período definido. O limite máximo de aposta pode ser definido ao longo
                de um dia, uma semana ou um mês. O limite não é líquido, portanto, os lucros são excluídos. Isso significa
                que, se você for lucrativo durante o período, esses lucros não serão deduzidos do seu limite máximo de
                aposta. Para aumentar ou diminuir seus limites, você deve remover os limites existentes e criar novos
                limites. Após remover os limites, um período de resfriamento de 24 horas será iniciado antes que você possa
                criar seu novo limite.
              </p>
            </div>

            <div>
              <h4 className="text-md font-bold mb-2">Autoexclusão</h4>
              <p className="mb-2">
                Tranque-se para fora do seu Bcash.bet por um período mais longo se o jogo estiver se tornando um problema
                para você. Você pode optar por uma autoexclusão de 6 meses, 1 ano, 2 anos e 5 anos. Se desejar informações
                detalhadas sobre como funciona a autoexclusão, <a href="/policy/autoremove">clique aqui</a>.
              </p>
            </div>
          </div>
        </div>
        <div>
          <h2 className="text-xl font-bold mb-4">O que é o Jogo Problemático?</h2>
          <p className="mb-4">
            Uma pequena população de pessoas que jogam são consideradas jogadores problemáticos. Para essas pessoas, o jogo
            deixa de ser divertido e começa a afetar negativamente outras áreas de sua vida pessoal, como:
          </p>
          <ul className="list-disc ml-8 mb-4">
            <li>Bem-estar mental</li>
            <li>Saúde física</li>
            <li>Relações pessoais</li>
            <li>Situação financeira</li>
          </ul>

          <p className="mb-4">Sinais de que você pode estar desenvolvendo o Jogo Problemático:</p>
          <ul className="list-disc ml-8 mb-4">
            <li>Ficar excessivamente chateado com as perdas.</li>
            <li>Perseguir suas perdas.</li>
            <li>Não conseguir parar ou reduzir o jogo.</li>
            <li>Esconder o jogo dos outros e se defender em relação a perdas ou tempo gasto jogando.</li>
            <li>Negligenciar responsabilidades (financeiras e relacionamentos pessoais).</li>
            <li>Usar o jogo como uma fuga para outros problemas.</li>
          </ul>

          <p className="mb-4">Recursos Adicionais para Ajudar</p>
          <p className="mb-4">
            Se você está preocupado consigo mesmo ou se alguém que você conhece está com dificuldades para controlar o jogo,
            existem várias agências de suporte externo que podem ajudar com aconselhamento.
          </p>
          <p className="mb-4">
            Converse com alguém em quem você confia sobre seu jogo - como um amigo íntimo ou membro da família, líder
            religioso ou comunitário, médico ou outro profissional de saúde.
          </p>

          <div>
            <h3 className="text-lg font-bold mb-2">Menores</h3>
            <p className="mb-2">O Bcash.bet não apoia o jogo por menores.</p>
            <p className="mb-2">
              O jogo por menores é ilegal e é definido como qualquer pessoa com menos de 18 anos no momento do registro da
              conta.
            </p>
            <p className="mb-2">
              Nenhum prêmio deve ser pago a tais jogadores se forem identificados como menores de idade.
            </p>

            <div>
              <h4 className="text-md font-bold mb-2">Dicas para os Pais</h4>
              <ul className="list-disc ml-8 mb-4">
                <li>Não deixe seu computador sem vigilância quando seu software de cassino estiver em execução.</li>
                <li>Proteja sua senha do programa de cassino.</li>
                <li>Não permita que menores participem de atividades de jogo.</li>
                <li>Mantenha o número de sua conta de cassino e seus cartões de pagamento fora do alcance de menores.</li>
                <li>Não salve senhas em seu computador.</li>
                <li>
                  Limite o tempo que seus filhos passam online e use software para evitar que eles acessem material
                  inadequado.
                </li>
              </ul>
            </div>

            <div>
              <h4 className="text-md font-bold mb-2">Ferramentas de Bloqueio e Filtragem de Apostas</h4>
              <p className="mb-2">
                Para garantir a segurança das crianças na Internet, o Bcash.bet incentiva seus jogadores a usar software
                de filtragem para evitar que menores acessem determinado conteúdo online:
              </p>
              <ul className="list-disc ml-8">
                <li>Gamcare.</li>
                <li>Netnanny.</li>
                <li>Betblocker.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ResponsiblePage
