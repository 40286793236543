// In your PartnerPage component
import React, { useEffect, useState } from 'react'
import Gift from '@/components/atoms/Icons/Gift'
import Coin from '@/components/atoms/Icons/Coin'
import IconFacebook from '@/components/atoms/Icons/Facebook'
import IconInstagram from '@/components/atoms/Icons/IconInstagram'
import IconKwai from '@/components/atoms/Icons/IconKwai'
import IconTelegramAlt from '@/components/atoms/Icons/IconTelegramAlt'
import IconTiktok from '@/components/atoms/Icons/IconTiktok'
import IconWhatsappFill from '@/components/atoms/Icons/IconWhatsappFill'
import LinkCopy from '@/components/atoms/Icons/LinkCopy'
import { IPartnerResult } from '@/interfaces/application/partner'
import useApiService from '@/service/api.hook'
import { RootState } from '@/store/store'
import copy from 'clipboard-copy'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import bannerRight from '@/assets/banner/bonus-1000-wallet.png'

const PartnerPage: React.FC = (): JSX.Element => {
  const [optionSelected, setOptionSelected] = useState('bonus')
  const [resultPartner, setResultPartner] = useState<Partial<IPartnerResult>>({})
  const inviteCode = useSelector((state: RootState) => state.user.inviteCode)
  const linkInvite = `https://Bcash.bet?invite=${inviteCode}`
  const { getPartnerResult, redeemCode } = useApiService() // Add redeemCode here
  const ruleRedux = useSelector((state: RootState) => state.user.ruleId)
  const [rule, setRule] = useState<number>(ruleRedux)
  const [promoCode, setPromoCode] = useState<string>('') // State for input

  useEffect(() => {
    setRule(ruleRedux)
  }, [ruleRedux])

  const text =
    rule === parseInt(import.meta.env.VITE_INFLUENCER_RULE_ID, 10)
      ? {
          title: 'Parcerias',
          subtitle: 'Escolha um das opções abaixo',
        }
      : {
          title: 'Convide e Ganhe',
          subtitle: 'Convide seus amigos para conhecer a Bcash.bet e ganha R$10!',
        }

  async function handleGetResult() {
    setOptionSelected('results')
    const result = await getPartnerResult()
    if (result.data) setResultPartner(result.data)
  }

  const handleRedeem = async () => {
    if (!promoCode) {
      toast.error('Por favor, insira um código promocional.')
      return
    }
    const response = await redeemCode(promoCode)
    if (response.error) {
      toast.error(response.errorObj) // Display error message directly from the API
    } else {
      toast.success(response.data || 'Código resgatado com sucesso!')
    }
  }

  const itensResult = [
    {
      id: 1,
      label: 'Total de convites',
      value: resultPartner.totalInvited || 0,
    },
    {
      id: 2,
      label: 'Renda total',
      value: `R$${(resultPartner.totalMoney || 0).toFixed(2)}`,
    },
    {
      id: 3,
      label: 'Convidados hoje',
      value: resultPartner.totalInvited || 0,
    },
    {
      id: 4,
      label: 'Renda hoje',
      value: `R$${(resultPartner.moneyToday || 0).toFixed(2)}`,
    },
    {
      id: 5,
      label: 'Estimativa salário',
      value: `R$${(resultPartner.moneyCurrentMonth || 0).toFixed(2)}`,
    },
    {
      id: 6,
      label: 'Salário último mês',
      value: `R$${(resultPartner.moneyLastMonth || 0).toFixed(2)}`,
    },
    {
      id: 7,
      label: 'Convidados/mês',
      value: resultPartner.invitedPerMonth || 0,
    },
    {
      id: 8,
      label: 'Depositantes',
      value: resultPartner.deposit || 0,
    },
  ]

  const handleCopyLink = () => {
    copy(linkInvite)
    toast.success('Link copiado com sucesso!')
  }

  return (
    <div>
      <div className="flex flex-col lg:flex-row mb-4 gap-5">
        <div className="bg-pallet1 w-full p-4 rounded-xl border-pallet2 border flex-1">
          <h1 className="pb-1 text-xl font-medium text-center lg:text-left">{text.title}</h1>
          <p className="mt-2 text-slate-300 text-sm text-center lg:text-left">{text.subtitle}</p>
          <div className="mt-6 flex gap-3 flex-col lg:flex-row flex-wrap">
            <span
              onClick={() => setOptionSelected('invite link')}
              className={`cursor-pointer px-3 py-1 justify-center lg:justify-start uppercase font-medium rounded-md inline-flex gap-2 items-center border ${
                optionSelected === 'invite link' ? 'bg-[#023AC5] border-pallet4' : 'border-pallet2'
              }`}
            >
              <LinkCopy />
              Link de convite
            </span>

            <span
              onClick={handleGetResult}
              className={`cursor-pointer px-3 py-1 justify-center lg:justify-start text-center uppercase font-medium rounded-md inline-flex gap-2 items-center border ${
                optionSelected === 'results' ? 'bg-[#023AC5] border-pallet4' : 'border-pallet2'
              }`}
            >
              <Coin />
              Resultados
            </span>

            <span
              onClick={() => setOptionSelected('bonus')}
              className={`cursor-pointer px-3 py-1 justify-center lg:justify-start uppercase font-medium rounded-md inline-flex gap-2 items-center border ${
                optionSelected === 'bonus' ? 'bg-[#023AC5] border-pallet4' : 'border-pallet2'
              }`}
            >
              <Gift />
              Bônus
            </span>
          </div>
        </div>
        <div className="hidden lg:block lg:w-[35%]">
          <img src={bannerRight} />
        </div>
      </div>
      <section className="bg-pallet1 w-full p-4 rounded-xl border-pallet2 border pb-8 flex-1">
        {optionSelected === 'invite link' && (
          <>
            <div className="flex flex-col gap-4 mb-4">
              <span className="pb-1 text-xl font-medium">1- Copie o link abaixo e divulgue nas suas redes sociais:</span>
              <div className="flex flex-col lg:flex-row items-center gap-4">
                <span className="px-4 py-2 font-medium rounded-md inline-flex gap-2 items-center border border-pallet2 w-full lg:w-auto">
                  {linkInvite}
                </span>
                <span
                  onClick={handleCopyLink}
                  className="cursor-pointer px-6 py-2 uppercase font-medium rounded-md inline-flex justify-center gap-2 items-center border bg-[#023AC5] border-pallet4 w-full lg:w-auto"
                >
                  Copiar link
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <span className="pb-1 text-xl font-medium">2- Toque nos ícones abaixo para compartilhar:</span>
              <div className="flex items-center gap-4 ">
                <Link
                  to={`https://api.whatsapp.com/send?text=${encodeURIComponent(linkInvite)}`}
                  target="_blank"
                  className="items-center border bg-gradient-to-r from-[#23a5f0] to-[#1051d3] border-pallet4 rounded-full p-2"
                >
                  <IconWhatsappFill height={28} width={28} />
                </Link>
                <Link
                  to={`https://www.tiktok.com/en/create?text=${encodeURIComponent(linkInvite)}`}
                  target="_blank"
                  className="items-center border bg-gradient-to-r from-[#23a5f0] to-[#1051d3] border-pallet4 rounded-full p-2"
                >
                  <IconTiktok height={28} width={28} />
                </Link>
                <Link
                  to={`https://www.instagram.com/share?url=${encodeURIComponent(linkInvite)}&title=Bcash.bet`}
                  target="_blank"
                  className="items-center border bg-gradient-to-r from-[#23a5f0] to-[#1051d3] border-pallet4 rounded-full p-2"
                >
                  <IconInstagram height={28} width={28} />
                </Link>
                <Link
                  to={`https://t.me/share/url?url=${encodeURIComponent(linkInvite)}&text=Bcash.bet`}
                  target="_blank"
                  className="items-center border bg-gradient-to-r from-[#23a5f0] to-[#1051d3] border-pallet4 rounded-full p-2"
                >
                  <IconTelegramAlt height={28} width={28} />
                </Link>
                <Link
                  to={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(linkInvite)}`}
                  target="_blank"
                  className="items-center border bg-gradient-to-r from-[#23a5f0] to-[#1051d3] border-pallet4 rounded-full p-2"
                >
                  <IconKwai height={28} width={28} />
                </Link>
                <Link
                  to={`https://www.kwai.com/share/user/${encodeURIComponent(linkInvite)}`}
                  target="_blank"
                  className="items-center border bg-gradient-to-r from-[#23a5f0] to-[#1051d3] border-pallet4 rounded-full p-2"
                >
                  <IconFacebook height={28} width={28} />
                </Link>
              </div>
            </div>
          </>
        )}
        {optionSelected === 'results' && (
          <div>
            <h1 className="px-4 pb-1 text-xl font-medium text-center lg:text-left">Resultados</h1>

            <div className="grid grid-cols-1 lg:grid-cols-2 w-full p-1 lg:p-4 gap-4">
              {itensResult.map((item) => (
                <div key={item.id} className="flex gap-5 w-full px-4 h-[40px]">
                  <span className="w-1/2">{item.label}:</span>
                  <div
                    className={`flex items-center justify-center font-bold opacity-90 border border-pallet9 w-1/2 rounded-md ${
                      item.id === 2 ? 'bg-[#FFB700]' : ''
                    }`}
                  >
                    <span>{item.value}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {optionSelected === 'bonus' && (
          <div>
            <h1 className="pb-1 text-xl font-medium text-center lg:text-left">Reivindicar código promocional</h1>
            <p className="mt-2 text-slate-300 text-sm text-center lg:text-left">
              Um lugar que vale apena conferir diariamente, você pode ser presenteado com um bônus nosso a qualquer momento!
            </p>
            <div className="flex flex-col lg:flex-row items-center gap-4 mt-4">
              <input
                type="text"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                className="px-4 py-2 font-medium rounded-md inline-flex gap-2 items-center border border-pallet2 w-full lg:w-auto bg-transparent"
                placeholder="xxxxxxxxxxxxxxx"
              />

              <button
                onClick={handleRedeem}
                className="cursor-pointer px-6 py-2 uppercase font-medium rounded-md inline-flex justify-center gap-2 items-center border bg-[#023AC5] border-pallet4 w-full lg:w-auto"
              >
                REIVINDICAR
              </button>
            </div>
          </div>
        )}
      </section>
      <div className="lg:hidden pt-4">
        <img src={bannerRight} />
      </div>
    </div>
  )
}

export default PartnerPage
