import React from 'react'

const BonusPage: React.FC = (): JSX.Element => {
  return (
    <section className="bg-pallet1  w-full p-4 rounded-xl border-pallet2 border">
      <div className="p-8 w-full mx-auto p-6 rounded shadow">
        <h1 className="text-2xl font-bold mb-4">TERMOS DE BÔNUS</h1>

        <p className="mb-6">
          1. Todas as ofertas de bônus estão estritamente limitadas a uma por pessoa e sua família, endereço residencial,
          endereço de e-mail, endereço IP e número de telefone.
        </p>

        <p className="mb-6">
          2. Ao indicar um novo usuário para o cassino e ele efetuar o cadastro com sucesso, você receberá R$15 em valor
          BÔNUS. Sujeito a requisitos de aposta conforme dito no termo.
        </p>

        <p className="mb-6">
          3. Bonus de 200% e 1000% estão sujeitos a requisitos de aposta de 60x vezes o valor do próprio bônus.
        </p>

        <p className="mb-6">
          4. Ao realizar o primeiro depósito, caso o valor seja superior a 100, aplicaremos um bônus multiplicador de 1000%.
          Para ilustrar, considere o seguinte exemplo:
        </p>
        <p className="ml-6 mb-2">- Se o valor do depósito for exatamente 100, o bônus será calculado da seguinte forma:</p>
        <ul className="list-disc ml-12 mb-2">
          <li>Valor do depósito: 100</li>
          <li>Multiplicador: 1000%</li>
          <li>Bônus: 1000 - 100 = 900</li>
        </ul>
        <p className="ml-6 mb-2">
          - Se o valor do depósito estiver entre 101 e 1000, aplicaremos um multiplicador de 1000% até o limite de 100. Em
          seguida, aplicaremos um multiplicador adicional de 200% sobre o restante do saldo. Para exemplificar:
        </p>
        <ul className="list-disc ml-12 mb-2">
          <li>Valor do depósito: 200</li>
          <li>Multiplicador inicial: 1000%</li>
          <li>Bônus inicial: 1000 - 100 = 900</li>
          <li>Multiplicador adicional: 200%</li>
          <li>Saldo final: 1000 + 100 * 200% = 1200</li>
        </ul>

        <p className="mb-6">
          5. Todos os bônus têm um requisito mínimo de depósito para obter o bônus, um limite máximo possível para o valor
          do bônus, termos de aposta, um limite máximo para as apostas enquanto estiver cumprindo os requisitos.
        </p>

        <p className="mb-6">
          A administração da Bcash.bet reserva-se o direito de cancelar quaisquer bônus e/ou ganhos obtidos por meio de
          comportamento fraudulento.
        </p>

        <p className="mb-6">
          A equipe de gestão da Bcash.bet tem autoridade para atualizar estas diretrizes a qualquer momento.
        </p>
      </div>
    </section>
  )
}

export default BonusPage
