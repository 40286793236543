import FormButton from '@/components/atoms/FormButton'
import FormInput from '@/components/atoms/FormInput'
import IconPadlock from '@/components/atoms/Icons/Padlock'
import IconUser from '@/components/atoms/Icons/UserLarge'
import Modal from '@/components/atoms/Modal'
import { ButtonType } from '@/interfaces/application/buttonType'
import useApiService from '@/service/api.hook'
import { setAppData } from '@/store/app/actions'
import { RootState } from '@/store/store'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha-enterprise'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import bcashIcone from '@/assets/icones/logo-modal.png';

export type FormSignin = {
  login: string
  password: string
}

const ModalSignin: React.FC = (): JSX.Element => {
  const isDevelopment = true //import.meta.env.VITE_MODE === 'development'
  const recaptchKey = import.meta.env.VITE_RECAPTCHA_SITE_KEY
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const [isVerified, setIsverified] = useState<boolean>(isDevelopment)
  const dispatch = useDispatch()
  const { signIn, verifyCaptcha } = useApiService()
  const openModal = useSelector((state: RootState) => state.app.modalSignIn)
  const name = useSelector((state: RootState) => state.user.login || state.user.name)

  const schema = Yup.object().shape({
    login: Yup.string().required('Campo obrigatório'),
    password: Yup.string().required('Campo obrigatório'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<FormSignin>({
    resolver: yupResolver(schema),
  })

  const closeModalSignIn = () => {
    dispatch(setAppData({ modalSignIn: false }))
  }

  const openSigUp = () => {
    dispatch(setAppData({ modalSignUp: true, modalSignIn: false, modalRecoveryPassword: false }))
  }

  const openRecovery = () => {
    dispatch(setAppData({ modalSignUp: false, modalSignIn: false, modalRecoveryPassword: true }))
  }

  const handleCaptchaSubmission = async (token: string | null): Promise<void> => {
    try {
      const statusCaptcha = await verifyCaptcha(token)

      if (!statusCaptcha.error) {
        setIsverified(true)
      } else {
        setIsverified(false)
      }
    } catch (error) {
      setIsverified(false)
    }
  }

  const handleFormSubmit: SubmitHandler<FormSignin> = async (data: FormSignin): Promise<void> => {
    await signIn(data.login, data.password)
      .then((result) => {
        if (!result.error) {
          reset()
          closeModalSignIn()
          toast.success(`Bem vindo ${name}!`)
        } else {
          toast.error(`${result.errorObj.response.data.message}`)
        }
      })
      .catch((err) => {
        console.error('Erro ao realizar login', err)
        toast.error(`${err.response?.data?.message || err.message}`)
      })
  }

  return (
    <Modal isOpen={openModal} size="extra-small" closeModal={() => closeModalSignIn()}>
      <div className="w-full px-2">
        <div className="flex justify-center mb-5">
          <img src={bcashIcone} alt="Logo" className="h-8" />
        </div>
        <p className="text-center uppercase text-white font-medium">ACESSAR SUA CONTA</p>
        <div className="flex justify-center items-center gap-1 mb-5">
          <span className="font-thin text-xs text-center text-gray-400">Insira seu email ou nome de usuário e senha</span>
        </div>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <FormInput
            className="mb-3"
            register={register}
            errors={errors}
            control={control}
            required
            icon={<IconUser />}
            type="text"
            name="login"
            autoComplete="username"
            placeholder="Insira o seu email ou nome de usuário"
            label="E-mail ou Nome de usuário"
          />

          <FormInput
            className="mb-3"
            register={register}
            errors={errors}
            control={control}
            required
            type="password"
            name="password"
            placeholder="Digite uma senha"
            autoComplete="current-password"
            label="Senha"
            icon={<IconPadlock />}
          />

          {!isDevelopment && (
            <div className="mb-5 flex items-center justify-center">
              <ReCAPTCHA sitekey={recaptchKey} ref={recaptchaRef} onChange={handleCaptchaSubmission} />
            </div>
          )}

          <div className="text-xs mb-5">
            <div
              className="flex font-thin justify-center items-center gap-1  cursor-pointer"
              onClick={openRecovery}
            >
              Esqueceu a senha?
            </div>
            <div className="flex font-thin justify-center items-center gap-1 text-gray-400">
              Não possui conta?{' '}
              <span className=" cursor-pointer text-white" onClick={openSigUp}>
                Registra-se
              </span>
            </div>
          </div>

          <div className="mb-5 px-5">
            <FormButton
              title="Entrar"
              type="submit"
              className="uppercase font-medium rounded-md "
              buttonType={!isVerified ? ButtonType.Disabled : ButtonType.Confirm}
            ></FormButton>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default ModalSignin
